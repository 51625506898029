import { UUID } from "crypto";
import { observer } from "mobx-react";
import React from 'react';

import { Button, Intent, Spinner } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';
import { IObservableArray, makeObservable, observable, runInAction } from "mobx";
import { useInView } from 'react-intersection-observer';
import { Api } from "../api/Api";
import { ListShelfItem } from "../api/Shelf";
import { showError, showSuccess, showWarning } from "../dialog/Notification";
import { openShareLinkDialog } from "../dialog/ShareLinkDialog";
import { rootNavigate } from "../navigation/CustomRouter";
import { ROUTE_PROFILE } from "../navigation/Routes";
import { anyToText } from "../util";



export type ProgressiveShelfGalleryProps = {
    items: ListShelfItem[]
}

export type LoadMoreButtonProps = {
    onClick?: VoidFunction
    onInView?: (inView: boolean) => void
    disabled: boolean
    text: string
}

const LoadMoreButton = (props: LoadMoreButtonProps) => {
    const { ref, inView } = useInView({ threshold: 0 });
    if (inView && props.onInView) {
        props.onInView(inView)
    }
    return (
        <div ref={ref}>
            <Button
                icon={IconNames.DOWNLOAD}
                text={props.text}
                onClick={props.onClick}
                intent={Intent.PRIMARY}
                large
                disabled={props.disabled}
            />
        </div>
    );
}




const min = (a: number, b: number) => a > b ? b : a;

@observer
class ProgressiveShelfGallery extends React.PureComponent<ProgressiveShelfGalleryProps> {
    @observable private n: number = 0; // number of images to diplays from items
    @observable lastImageShown: boolean = false; // set to true when the nth image is shown in the viewport
    @observable showLoadMore: boolean = false; // display the "load more" button or not?

    @observable loadedImages: IObservableArray<boolean> = observable([]);
    @observable allImagesLoaded: boolean = false; // set to true when the nth image is shown in the viewport

    @observable _key: number = 0;


    constructor(props: ProgressiveShelfGalleryProps) {
        super(props);
        makeObservable(this)
        this.reinit()
    }

    reinit = () => {
        // console.log("reinit")
        runInAction(() => {
            this.n = min(this.props.items.length, 10) // default n is 10 or the max possible
            this.loadedImages.clear()
            this.allImagesLoaded = false
            this._key = 0;
            this.lastImageShown = false
        })
    }

    // called when the loadMore button after the nth image appears or disappears in the viewport
    onInView = (inView: boolean) => {
        // console.log("onInView", inView)
        runInAction(() => {
            this.lastImageShown = inView;
            this.loadMoreToFillViewport()
        })
    }

    // call this to add more images to the dom and load them (does nothing if there are no more images)
    loadMore = (args?: any): void => {
        runInAction(() => {
            const n = min(this.n + 10, this.props.items.length)
            // console.log("loadMore", this.n, "->", n)
            if (n > this.n) {
                this.n = n
            }
        })
    }

    // returns the number of images that are already added to the DOM but not yet loaded
    unloadedImageCount = () => {
        let notLoaded = (this.loadedImages.slice(0, this.n).filter(value => value !== true)).length;
        if (this.loadedImages.length < this.n) {
            notLoaded += this.n - this.loadedImages.length
        }
        return notLoaded
    }

    // this will call loadMore() if all images are loaded, and the last image is visible in the viewport
    loadMoreToFillViewport = () => {
        const ucnt = this.unloadedImageCount();
        // console.log("onImageLoaded, ucnt=", ucnt, " lastImageShown=", this.lastImageShown)
        if (ucnt === 0 && this.lastImageShown) {
            this.loadMore()
        }
    }

    // called when a single image is loaded, also called when an image fails to load
    onImageLoaded = (event: React.SyntheticEvent<HTMLImageElement>) => {
        const index = parseInt(event.currentTarget.getAttribute("data-idx")!)
        runInAction(() => {
            this.loadedImages[index] = true;
            this._key += 1;
            this.lastImageShown = false;
            this.loadMoreToFillViewport()
        })
    }

    addToShelf = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const productId = event.currentTarget.getAttribute("data-product-id")! as UUID;
        // console.log(productId)
        try {
            const res = await Api.shelf.add_product(productId);
            if (res.shelf === null) {
                showWarning(
                    <span>
                        You don't have an active paid subscription with at least one product available.
                        <br />
                        <Button icon={IconNames.LINK} onClick={() => rootNavigate(ROUTE_PROFILE)}>Go to your profile</Button>
                    </span>,
                    "Could not add to shelf"
                )
            } else if (res.is_new) {
                showSuccess("New item added to your shelf.")
            } else {
                showSuccess("Item was already on your shelf.")
            }
        } catch (error) {
            showError(error)
        }
    }


    /* renderItem = (item: ListShelfItem, dataIdx: number) => {
         return <div key={item.file_id}>
             <div>
                 <ul>                    
                     <li>Requested: {anyToText(item.c_tim)}</li>
                     <li>Completed: {item.completed?anyToText(item.completed):"Not yet completed"}</li>
                     <li>Failed: {item.failed?anyToText(item.failed):null}</li>
                     <li>Number of unprocessed images: {anyToText(item.nwaiting)}</li>
                     <li>{item.completed?<a href={`/product/download_zip/${item.product_id}`}>Download ZIP file</a>:null}</li>
                 </ul>
             </div>
             <div>
                 <img src={`/media/file/${item.file_id}`}
                     alt={item.file_id}
                     data-idx={dataIdx}
                     data-product-id={item.product_id}
                     data-thumb-id={item.file_id}
                     onLoad={this.onImageLoaded}
                     onError={this.onImageLoaded}
                     style={{objectFit: "contain", width:256, height:256}}
                 />
             </div>
         </div>
     }  Old version by Laci */

    createShareLink = async (event: React.SyntheticEvent<HTMLElement>) => {
        const product_id = event.currentTarget.getAttribute("data-product-id")! as UUID;
        try {
            const url = await Api.shelf.create_zip_download_link(product_id);
            openShareLinkDialog(url);
        } catch (error) {
            showError(error)
        }
    }


    renderItem = (item: ListShelfItem, dataIdx: number) => {
        return (
            <div key={item.file_id} className="product-tile">
                <div className="product-info">
                    <ul className="product-details">
                        <li><b>Requested:</b> {anyToText(item.c_tim)}</li>
                        <li><b>Completed:</b> {item.completed ? anyToText(item.completed) : "Not yet completed"}</li>
                        <li><b>Failed:</b> {item.failed ? anyToText(item.failed) : 0}</li>
                        <li><b>Unprocessed formats for ZIP file: <span className={item.completed ? "count-down-color-green" : "count-down-color-red"}>{anyToText(item.nwaiting)}</span></b></li>
                        {item.completed ?
                            <>
                                <li>
                                    <a href={`/product/download_zip/${item.product_id}`}><u>Download ZIP file here</u></a>
                                </li>
                                <li>
                                    <Button
                                        text="Click to share download link"
                                        icon={IconNames.SHARE}
                                        intent={Intent.PRIMARY}
                                        data-product-id={item.product_id}
                                        onClick={this.createShareLink}
                                    />
                                </li>
                            </>
                            :
                            <li>
                                <b>Please wait while we are packing your ZIP file</b><Spinner />
                            </li>
                        }
                    </ul>
                </div>
                <div className="product-image">
                    <img src={`/media/file/${item.file_id}`}
                        alt={item.file_id}
                        data-idx={dataIdx}
                        data-product-id={item.product_id}
                        data-thumb-id={item.file_id}
                        onLoad={this.onImageLoaded}
                        onError={this.onImageLoaded}
                    />
                </div>
            </div>
        );
    }

    renderItems = () => {
        return (
            <div className="product-grid-container">
                {this.props.items.slice(0, this.n).map((item, index) => this.renderItem(item, index))}
            </div>
        );
    }

    render() {
        const canLoadMore = this.n < this.props.items.length;
        return <>
            {this.renderItems()} {/* Call renderItems here */}
            <LoadMoreButton
                key={this._key}
                onInView={this.onInView}
                disabled={!canLoadMore}
                text={canLoadMore ? "Load more..." : "No more items"}
            />
        </>
    }
}

export default ProgressiveShelfGallery;
