import * as React from "react";

import { Callout, Intent, Spinner } from "@blueprintjs/core";
import { UUID } from "crypto";
import { observable, reaction, runInAction } from "mobx";
import { observer } from 'mobx-react';

import { dispatcher } from "../App";
import { showError } from "../dialog/Notification";

import _ from "lodash";
import ProgressiveGallery from "../components/ProgressiveGallery";
import { uiStore } from "../store/UIStore";



const loading = observable.box<boolean>(false)
const setLoading = (value: boolean) => { runInAction(() => loading.set(value)) }

type SearchHit = {
    product_id: UUID
    file_id: UUID
    ts_rank: number
}

type DlSearchResult = {
    results: SearchHit[]
    ts_query: string
    warning: string
}

const EMPTY_SEARCH_RESULTS: DlSearchResult = {
    results: [],
    ts_query: "",
    warning: ""
}

const searchResult = observable.box<DlSearchResult>(EMPTY_SEARCH_RESULTS)
const lastSearchText = observable.box<string>("")
const searchPageIsDisplayed = observable.box<boolean>(false)

const reloadSearch = async () => {
    try {
        setLoading(true)
        const sp = uiStore.searchText;
        if (sp) {
            const params: any = {
                lang_id: '9cfefed8-fb94-97ba-a5cd-519d7d2bb5d7', // TODO!
                search_phrase: sp
            }
            const data = await dispatcher.call<DlSearchResult>("search.search", params)
            runInAction(() => {
                searchResult.set(data);
                loading.set(false);
            })
        } else {
            runInAction(() => {
                searchResult.set(EMPTY_SEARCH_RESULTS);
                loading.set(false);
            })
        }
        lastSearchText.set(sp)
    } catch (error) {
        showError(error)
    }
}

const reloadSearchIfChanged = async () => {
    if (uiStore.searchText !== lastSearchText.get() && searchPageIsDisplayed) {
        return reloadSearch()
    }
}

const debouncedReloadSearch = _.debounce(reloadSearchIfChanged, 1000, { maxWait: 100000 })

reaction(
    () => [uiStore.searchText, searchPageIsDisplayed.get()],
    () => {
        setLoading(true)
        // Note: mobx reaction throttle is not the same as a debounce
        debouncedReloadSearch()
    }
)

interface SearchPageProps { }




@observer
class SearchPage extends React.PureComponent<SearchPageProps> {
    componentDidMount(): void {
        runInAction(() => searchPageIsDisplayed.set(true))
    }
    componentWillUnmount(): void {
        runInAction(() => searchPageIsDisplayed.set(false))
    }

    renderResults = (items: SearchHit[]) => {
        return <ProgressiveGallery items={items} />
    }


    render() {
        const res = searchResult.get();
        let helperText = res.ts_query
        if (!helperText) {
            helperText = "Enter your search phrase.";
        }
        return <>
            {res.warning ? <Callout intent={Intent.WARNING}>{res.warning}</Callout> : null}
            {loading.get() ? <Spinner /> : this.renderResults(res.results)}
        </>

    }

}

export default SearchPage;
