import { intervalConverter } from "./interval";
import { AbcConverter, ICustomType } from "./types";
import { timestampConverter } from "./timestamptz";
import { dateConverter } from "./date";
//import { uuidConverter } from "./uuid";

const ABC_CONVERTERS: { [typeCode: string]: AbcConverter<ICustomType> } = {};

const registerAbcConverter = (converter: AbcConverter<ICustomType>): void => {
    const typeCode = converter.getTypeCode();
    if (ABC_CONVERTERS[typeCode]) {
        throw new Error("AbcConverter already registered for typeCode=" + typeCode);
    } else {
        ABC_CONVERTERS[typeCode] = converter;
    }
};

export const isAbcConverterRegistered = (typeCode: string): boolean => {
    return !!ABC_CONVERTERS[typeCode];
};

export const getAbcConverter = (typeCode: string): AbcConverter<ICustomType> => {
    const result = ABC_CONVERTERS[typeCode];
    if (result) {
        return result;
    } else {
        throw new Error("No AbcConverter has been registered for typeCode= " + typeCode);
    }
};

export const registerAllAbcConverters = () => {
    registerAbcConverter(intervalConverter);
    registerAbcConverter(timestampConverter);
    registerAbcConverter(dateConverter);
    //registerAbcConverter(uuidConverter);
};
