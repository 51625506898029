import { dispatcher } from "../App";
import { ITimestamp } from "../data/abc/timestamptz";

export interface IProfileConfirmInfo {
    created: ITimestamp | null;
    valid_until: ITimestamp | null;
    confirmed: ITimestamp | null;
}

export interface IProfileInfo {
    id: string; // uuid
    email: string;
    fullname: string;

    // TODO - these are not stored on the server yet!
    valid_from: ITimestamp | null;
    valid_until: ITimestamp | null;

    email_confirm: IProfileConfirmInfo;
}

export class Security {
    /**
     * 
     * Return information about the currently logged in user.
     * 
     */
    public async me(): Promise<IProfileInfo> {
        return dispatcher.call<IProfileInfo>("security.me");
    }
}
