import { dispatcher } from "../App";
import { IRecord, RecordId } from "./Record";
import { appStore } from "../store/AppStore";

export interface IViewOrderBy {
    column_name: string;
    ascending?: boolean; // default: true
}

/** A dictionary of named filter parameters. */
export type ViewFilterExprParams = { [paramName : string] : any };

// Warning! If you change this then you MUST also change ViewSourceConfiguration.mergeFilters below!
export interface IViewFilter {
    /** Simple text search in all textual fields. You should only used this for tables with a few number of rows. */
    simple?: string;
    /** Simple filter for column name value equality.  */
    equals: { [columnName: string]: any };
    /** Arbitrary expression using colum names, operators and values. */
    expr?: string;
    /** Parameters for the arbitrary expression filter. */
    params? : ViewFilterExprParams;
}

export interface IViewListParams {
    oid: number;
    filter? : IViewFilter;
    order_by?: IViewOrderBy[];
    limit?: number;
}

/** Merge multiple filters into a single one. */
export const mergeFilters = (filters:IViewFilter[], oid:number):IViewFilter => {
    const result : IViewFilter = { equals:{} };
    let expr : string[]  = [];
    let params : ViewFilterExprParams = {};
    filters.forEach(filter => {
        if (filter.simple!==undefined) {
            result.simple = filter.simple;
        }
        for (let columnName in filter.equals) {
            const value = filter.equals[columnName];
            if (value!==undefined) {
                result.equals[columnName] = value;
            }
        }
        // TODO: use "AND" instead of simple overwrite!
        if (filter.expr !== undefined) {
            expr.push(filter.expr);
        }
        if (filter.params !== undefined) {
            params = { ...params, ...filter.params };
        }
    });
    if (expr.length>0) {
        result.expr = expr.map(item => ` ( ${item} )`).join(" AND ");
        result.params = params;
    }
    return result;
}

export class View {
    /** List records in a view */
    // tslint:disable-next-line:variable-name
    public async list<T extends IRecord[]>(params: IViewListParams): Promise<T> {
        return dispatcher.call<T>("view.list", { ...params });
    }

    /** 
     * Load a single row by its id.
     * 
     * Records loaded by this method will be automatically stored in the LRU cache.
     * 
     * Whenever possible, please use loadCached instead. The only case when you should
     * prefer load() instead of loadCached() is when you already know that the data
     * was probably modified on the server, and/or you are willing to pay the price
     * for loading data from the server.
     * 
     */
    public async load<T extends IRecord | null>(oid: number, record_id: RecordId): Promise<T| null> {
        try {
            const record = await dispatcher.call<T>("view.load", { oid, record_id });
            if (record!==null) {
                appStore.getViewRecordCache(oid).set(record_id, record);
            }
            return Promise.resolve(record);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Similar to load(), but it tries to use the LRU cache for giving back the record.
     * 
     */
    public async loadCached<T extends IRecord | null>(oid: number, record_id: RecordId): Promise<T| null> {
        const result = appStore.getViewRecordCache(oid).get(record_id);
        if (result===undefined) {
            return this.load(oid, record_id);
        } else {
            return Promise.resolve(result);
        }
    }
}
