/* Low level communication functions. */

export type ServerConfig = {
    recaptcha_site_key_v2: string;
    recaptcha_site_key_v3: string;
    token_renew_window_seconds: number;
}

// use getServerConfig() instead.
let serverConfig : ServerConfig;


// Create website-relative websocket URL
export const getWsApiUrl = (relpath: string): string => {
    const proto: string = window.location.protocol === "https:" ? "wss" : "ws";
    const apiBase = process.env.REACT_APP_WEBSOCKET_API_BASE;
    if (apiBase) {
        // API base overriden by environment
        return apiBase + relpath;
    } else {
        // Default is relative to /api
        return proto + "://" + window.location.host + "/api/" + relpath;
    }
};

export const getHttpApiUrl = (relpath: string): string => {
    const proto: string = window.location.protocol === "https:" ? "https" : "http";
    //const apiBase = process.env.REACT_APP_HTTP_API_BASE;
    const apiBase = false;
    if (apiBase) {
        // API base overriden by environment
        return apiBase + relpath;
    } else {
        // Default is relative to /api
        return proto + "://" + window.location.host + "/api/" + relpath;
    }
};

// Connect a new Websocket to the server, resolve when opened.
export const connect = async (relpath?: string, onclose?: (evt: Event) => void): Promise<WebSocket> => {
    const apiUrl = getWsApiUrl(relpath || "/");
    //console.log("connect", apiUrl);
    const ws: WebSocket = new WebSocket(apiUrl);
    return new Promise((resolve, reject) => {
        ws.onopen = async (evt: Event) => {
            resolve(ws);
        };
        ws.onerror = async (evt: Event) => {
            reject(evt);
        };
        if (onclose) {
            ws.onclose = onclose;
        }
    });
};

export const fetchServerConfig = async (): Promise<ServerConfig> => {
    try {
        const response = await fetch(getHttpApiUrl("config"));
        const result = (await response.json()) as ServerConfig;
        serverConfig = result;
        return Promise.resolve(result);
    } catch (e) {
        return Promise.reject(e);
    }
};

export const getServerConfig = () : ServerConfig => {
    if (!serverConfig) {
        throw new Error("getServerConfig: fetchServerConfig was not called")
    }
    return serverConfig;
}