import * as React from 'react';
import { observable, computed, IObservableArray, runInAction } from 'mobx';

export class DialogStack {
    dialogs: IObservableArray<() => React.ReactElement> = observable.array([]);

    @computed get currentDialog(): (() => React.ReactElement) | null {
        if (this.dialogs.length) {
            return this.dialogs[this.dialogs.length - 1];
        } else {
            return null;
        }
    }

    public addDialog = (dialogElement: () => React.ReactElement) => {
        runInAction(() => {
            this.dialogs.push(dialogElement);
        })
    }

    public popLastDialog = () => {
        runInAction(() => {
            this.dialogs.pop();
        })
    }
}
