import { ObservableMap, action, makeObservable, observable, runInAction } from 'mobx';
import { RecordId } from '../api/Record';
import { IProfileInfo } from '../api/Security';
import { IViewFilter } from '../api/View';
import RecordSource from './record/RecordSource';
import { ViewRecordCache } from './view/ViewRecordCache';
import { ViewSource } from './view/ViewSource';
import { IViewSourceConfiguration } from './view/ViewSourceConfiguration';

class AppStore {
    @observable me: IProfileInfo | null = null;
    /** Map of RecordSource instances. Keys are not view names but ui paths. */
    @observable recordSources: ObservableMap<string, RecordSource> = observable.map({});

    /** Map of ViewSource instances. Keys are not view names but ui paths. */
    @observable viewSources: ObservableMap<string, ViewSource> = observable.map({});
    /** Map of ViewRecordCache instances. Keys are view oids. */
    @observable viewRecordCaches: ObservableMap<number, ViewRecordCache> = observable.map({});

    constructor() {
        makeObservable(this)
    }

    
    @action('AppStore.createViewSource') private createViewSource(storagePath: string, oid: number, masterFilter?: IViewFilter, defaultConfig?: Partial<IViewSourceConfiguration>) {
        this.viewSources.set(storagePath, new ViewSource(storagePath, oid, masterFilter, defaultConfig));
    }
    
    @action('AppStore.createViewRecordCache') private createViewRecordCache(oid: number) {
        this.viewRecordCaches.set(oid, new ViewRecordCache(oid));
    }    

    @action('AppStore.createRecordSource') private createRecordSource(storagePath: string, oid: number) {
        this.recordSources.set(storagePath, new RecordSource(oid));
    }


    /**
     * Return the viewSource for the given path. It will create the ViewSource if it does not exist yet. 
     * WARNING! The defaultConfig parameter is used ONLY when the ViewSource is first created
     * in the store. For already existing ViewSource instances, this is not used at all.
     * 
     */
    public getViewSource = (storagePath: string, oid: number, masterFilter?: IViewFilter, defaultConfig?: Partial<IViewSourceConfiguration>): ViewSource => {
        if (!this.viewSources.has(storagePath)) {
            this.createViewSource(storagePath, oid, masterFilter, defaultConfig);
            return this.viewSources.get(storagePath)!;
        } else {
            const result = this.viewSources.get(storagePath)!;
            if (masterFilter !== undefined) {
                runInAction(() => { result.masterFilter = masterFilter; })
            }
            return result;
        }

    }    

    /** Return a recordSource for a given path. Will create if it does not exist yet. */
    public getRecordSource = (storagePath: string, oid: number): RecordSource => {
        if (!this.recordSources.has(storagePath)) {
            this.createRecordSource(storagePath, oid);
        }
        return this.recordSources.get(storagePath)!;
    }

    /** Return a viewRecordCache for a given view. Will create if it does not exist yet. */
    public getViewRecordCache = (oid: number): ViewRecordCache => {
        if (!this.viewRecordCaches.has(oid)) {
            this.createViewRecordCache(oid);
        }
        return this.viewRecordCaches.get(oid)!;
    }

    public removeCachedViewRecords = (tbl_oid: number, rec_id: RecordId) => {
        // appStore.getViewRecordCache('autoview.' + table_path).del(rec_id); ???
        throw new Error("All views should report their 'primary' table oid for this")
    }

    /** Clear all data from the store (except 'me'). Useful for logging out. */
    @action.bound clearAll() {
        this.recordSources.clear();
        this.viewSources.clear();
    }    
}

export const appStore = new AppStore();