export interface IToken {
    // registered claims
    // https://auth0.com/docs/secure/tokens/json-web-tokens/json-web-token-claims#registered-claims
    iss: string;   // issuer, usually the base site URL
    iat: number;   // issued at, seconds after epoch
    exp: number;   // expires, issued at, seconds after epoch
    sub: string;   // subject, this is usually the user id
    
    //aud: string[]; // audience
    //nbf?: number;  // not before time, seconds after epoch
    //jti: string;   // JWT ID, unique identifier


    // private claims
    sid : string; // session id
    sxp : string; // session expires

    // raw token, this is not a claim but the whole token in raw
    raw: string; 
}

/**
 * Decode a raw token into an IToken
 */
export function decodeToken(rawToken: string): IToken {
    const token = JSON.parse(atob(rawToken.split(".")[1]));
    return { ...token, raw: rawToken };
}
