import { observable, computed, action, runInAction, reaction, IObservableValue, toJS, makeObservable } from 'mobx';

import { showError } from '../../dialog/Notification';
import { ViewSourceConfiguration, IViewSourceConfiguration } from './ViewSourceConfiguration';
import { IViewOrderBy, IViewFilter, mergeFilters } from '../../api/View';
import { Api } from '../../api/Api';
import { RelationMeta } from '../../api/Meta';
import { RecordId } from '../../api/Record';

let _dataKey: number = 0;
const genDataKey = (): number => {
    _dataKey += 1;
    return _dataKey;
}

/** This represents a list of rows that can be fetched from the server. */
export class ViewSource {
    public readonly storagePath: string;
    @observable public oid: number;
    @observable public rows: any[];
    @observable public rowIndex: number | null;
    /** `changed` indicates that query parameters have been changed. */
    @observable public changed: boolean;
    /** `loading` indicates that a new `list` request has been sent to the server */
    @observable public loading: boolean;
    @observable private _dataKey: IObservableValue<number>;
    @observable public readonly config: ViewSourceConfiguration;
    @observable public masterFilter: IViewFilter;
    /**
     * 
     * You can specify a column that MUST have a master filter value.
     * Without this value set, the ViewSource will set empty rows instead
     * of calling list() on the server.
     */
    @observable public needMasterFilterColumn ?: string;
    /**
     * refCount counts the number of mounted components that are displaying data from
     * this ViewSource. When refCount reaches zero, then the ViewSource will not be
     * auto-reoaded even if autoReload was set to True.
     * 
    */
    @observable private refCount : number;

    /**
     * 
     * Create a new ViewSource
     * 
     * @param storagePath This is the path in the appStore where the new ViewSource instance will be stored.
     * @param viewStruct Structure for the view. See api.meta.get_view_struct
     * @param masterFilter Initial master filter for the ViewSource. The master filter is always merged 
     *  with the user configured filter, and it cannot be ignored from the GUI.
     * @param defaultConfig Default configuration. When given, it will be used instead of the default-default
     *      empty configuration. This is useful when you want to override the default limit, orderBy or
     *      activeFilter parameters. Please note that the defaultConfig can (and probably will) be overriden
     *      by the user configuration later (loaded from UserConfig).
     */
    public constructor(storagePath: string, oid: number, masterFilter?: IViewFilter, defaultConfig?: Partial<IViewSourceConfiguration>) {
        makeObservable(this)

        this.storagePath = storagePath;
        this.oid = oid;
        this.rows = [];
        this.rowIndex = null;
        this.config = new ViewSourceConfiguration(this.storagePath, oid, defaultConfig);
        this.config.load()
        this.masterFilter = masterFilter===undefined?observable({ equals: {} }):masterFilter;
        this.changed = true;
        this.loading = false;
        this._dataKey = observable.box(0); // Zero indicates that this has never been loaded.
        this.refCount = 0;

        /* Keep `changed` up to date. */
        reaction(
            () => [this.config.toJs(), toJS(this.masterFilter), this.needMasterFilterColumn],
            this.setChanged,
            { name: 'ViewSource.setChanged' });
        /* Auto reload the ViewSource when parameters has been changed. */
        reaction(
            () => [this.changed, this.autoReload, this.needMasterFilterColumn],
            () => { if (this.changed && this.autoReload && this.refCount>0) { runInAction(() => { this.reload(); }); } },
            // TODO - make delay time configurabe. But how?
            { name: 'ViewSource.reloadWhenChanged', delay: 100 });
        // This is PROBABLY not needed, because refCount will be increased after the ViewSource has been created.
        //if (this.autoReload && this.refCount) { this.firstReload() };
    }

    /* TODO: do we need this?
    @computed get viewName(): string {
        return this.viewStruct.view_name;
    }
    */

    public get meta(): RelationMeta {
        return Api.meta.get_relation_meta(this.oid)
    }

    /** Current record. */
    @computed get currentRow(): any {
        if (this.rows && this.rowIndex !== null) {
            return this.rows[this.rowIndex];
        } else {
            return null;
        }
    }

    /** Identifier of current record. */
    @computed get currentRowId(): number | null {
        const record = this.currentRow;
        if (record) {
            return record.id!;
        } else {
            return null;
        }
    }

    /** Get number of rows in the view. */
    @computed get rowCount(): number {
        return this.rows.length;
    }

    /** Get row by rowIndex. */
    public getRow = (rowIndex: number | null): any => {
        if (this.rows && rowIndex !== null) {
            return this.rows[rowIndex];
        } else {
            return null;
        }
    }

    /** Get row id by rowIndex. */
    public getRowId = (rowIndex: number | null): number | null => {
        const row = this.getRow(rowIndex);
        if (row || row.id !== undefined) {
            return row.id;
        } else {
            return null;
        }
    }

    public get filter(): IViewFilter {
        return this.config.filter;
    }

    /** 
     * Merge the user configured fiters with the master filter.
     * masterFilter takes precedence!
     */
    @computed get mergedFilter(): IViewFilter {
        return mergeFilters([this.config.filter, this.masterFilter], this.oid);
    }

    @computed get masterIsValid() : boolean {
        //console.log(this.viewName, "masterIsValid #1", this.needMasterFilterColumn, this.needMasterFilterColumn===undefined);
        if (this.needMasterFilterColumn===undefined) {
            return true;
        } else {
            const result = !!this.masterFilter.equals[this.needMasterFilterColumn];
            //console.log(this.viewName, "masterIsValid #2", this.masterFilter.equals[this.needMasterFilterColumn], result);
            return result;
        }
    }

    private setChanged = () => {        
        //console.log(this.viewName, "changed! autoreload=", this.autoReload, "masterfilter=", toJS(this.masterFilter))
        runInAction(() => { this.changed = true; })        
    }

    /*
    @action.bound private clearChanged() {
        this.changed = false;
    }

    @action.bound private setLoading() {
        this.loading = true;
    }
    */

    @action.bound private clearLoading() {
        this.loading = false;
    }

    @action.bound private invalidateRows() {
        this._dataKey.set(genDataKey());
    }

    @computed public get dataKey(): number {
        return this._dataKey.get();
    }

    /** Append a new row. */
    @action.bound public appendRow(row: any) {
        this.rows.push(row);
        this.rowIndex = this.rows.length - 1;
        this.invalidateRows();
    }

    /** Overwrite contents of a given row. */
    @action.bound public setCurrentRow(row: any) {
        this.rows[this.rowIndex!] = row;
        this.invalidateRows();
    }

    /** Navigate to row by rowIndex. */
    @action.bound public goTo(rowIndex: number | null) {
        this.rowIndex = rowIndex;
    }

    @action.bound next(): boolean {
        if (this.rowIndex === null) {
            if (this.rowCount) {
                this.rowIndex = 0;
                return true;
            } else {
                return false;
            }
        } else if (this.rowIndex < this.rowCount - 1) {
            this.rowIndex += 1;
            return true;
        } else {
            return false;
        }
    }

    @action.bound prev(): boolean {
        if (this.rowIndex === null) {
            if (this.rowCount) {
                this.rowIndex = this.rowCount - 1;
                return true;
            } else {
                return false;
            }
        } else if (this.rowIndex > 0) {
            this.rowIndex -= 1;
            return true;
        } else {
            return false;
        }
    }

    /**
     * Load a row from the database (by id) and append it. 
     * 
     * This method ignores the LRU cache.
     * 
     * */
    public appendRowById = async (recordId: RecordId) => {
        try {
            const row = await Api.view.load<any>(this.oid, recordId);
            this.appendRow(row);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * 
     * Reload the current row from the database. 
     * 
     * This method ignores the LRU cache.
     * 
     * */
    public reloadCurrentRow = async () => {
        try {
            const recordId = this.currentRowId;
            if (recordId) {
                const row = await Api.view.load<any>(this.oid, recordId);
                if (row === null) {
                    // Record disappeared.
                    runInAction(() => {
                        const rowIndex = this.rowIndex!;
                        this.rows.splice(rowIndex, 1);
                        if (rowIndex > this.rows.length - 1) {
                            if (this.rows.length) {
                                this.rowIndex = this.rows.length - 1;
                            } else {
                                this.rowIndex = null;
                            }
                        }
                    })
                } else {
                    this.setCurrentRow(row);
                }
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     *  Reload all rows from the database.
     * 
     *  This is an async operation that will set the rows in an action later.
     * 
     */
    public reload = async () => {
        /* replace `changed` with `loading`. */
        runInAction(() => {
            this.changed = false;
            this.loading = true;
        });
        try {
            let rows : any[] = [];
            if (this.masterIsValid) {
                rows = await Api.view.list<any[]>({
                        oid: this.oid, 
                        filter: this.mergedFilter, 
                        order_by: this.config.orderBy, 
                        limit: this.config.limit
                });
            }
            runInAction(() => {
                this.rows = rows;
                if (rows.length) {
                    this.rowIndex = 0;
                } else {
                    this.rowIndex = null;
                }
                this.loading = false;
                this.invalidateRows();
            });
            return Promise.resolve();
        } catch (error) {
            this.clearLoading();
            showError(error);
            return Promise.reject(error);
        }
    }

    /** 
     * Reload all rows but ONLY if the viewSource has never been reloaded.
     */
    public firstReload = () => {
        if (this.dataKey === 0) {
            this.reload();
        }
    }

    @action.bound saveConfig() {
        return this.config.save();
    }

    /** Convenient method to change order by in the underlying configuration. */
    public setOrderBy = (orderBy: IViewOrderBy[]) => {
        this.config.setOrderBy(orderBy);
    }

    public get autoReload(): boolean {
        return this.config.autoReload;
    }

    public set autoReload(value: boolean) {
        runInAction(() => { this.config.autoReload = value; })
    }

    @action.bound incRefCount() {
        this.refCount += 1;
        /** Somebody just started displaying data for a changed autoReload-able viewSource. */        
        if (this.autoReload && this.refCount===1 && this.changed) {
            this.reload();
        }
        // console.log(this.storagePath+".refcount", this.refCount);
    }

    @action.bound decRefCount() {
        if (this.refCount<=0) { 
            //throw new Error(`Internal error: ViewSource ${this.storagePath} cannot decRefCount below zero.`); 
            console.log(`Internal error: ViewSource ${this.storagePath} cannot decRefCount below zero.`);
        } else {
            this.refCount -= 1;
        }
        // console.log(this.storagePath+".refcount", this.refCount);
    }

    public get simpleSearch(): string {
        return this.config.simpleSearch;
    }

    public set simpleSearch(value: string) {
        this.config.setSimpleSearch(value);
    }

    public get orderBy() {
        return this.config.orderBy;
    }

    public get hasIsActiveColumn(): boolean {
        return this.config.hasIsActiveColumn;
    }

    public get activeFilter(): boolean | null {
        return this.config.activeFilter;
    }

    public set activeFilter(value: boolean | null) {
        this.config.setActiveFilter(value);
    }

    public setFilterParam = (fieldName: string, value: any) => {
        this.config.setFilterParam(fieldName, value);
    }

}