import { ulid } from 'ulid'
import { ULIDtoUUID } from "ulid-uuid-converter";
import { dispatcher } from "../App";
import { IRecord, RecordId } from "./Record";
import { appStore } from "../store/AppStore";
import { UUID } from 'crypto';

// TODO
const ID_STEP = 1;
const JOURNAL_ATTRS = [
    "creation_time",
    "creation_user_id",
    "creation_session_id",
    "modification_time",
    "modification_user_id",
    "modification_session_id",

    "c_tim", "c_uid", "c_sid",
    "m_tim", "m_uid", "m_sid",
]
export class Crud {
    private static _lastId: number = 0;
    private static _remaining: number = 0;

    /** Create a new unique record identifier, number 
     * 
     * @param oid the oid of the relation
     * 
    */
    public static async nextNumberId(oid: number): Promise<number> {
        if (this._remaining <= 0) {
            this._lastId = await dispatcher.call<number>("next_id", {oid});
            this._remaining = ID_STEP;
            return this._lastId;
        } else {
            this._lastId += 1;
            this._remaining -= 1;
            return this._lastId;
        }
    }

    public static async nextUuidId(): Promise<UUID> {
        return ULIDtoUUID(ulid()) as UUID
    }

    public static async nextId(oid: number): Promise<RecordId> {
        throw new Error("TODO: implement Crud.nextId(oid)")
    }

    /** Load a single record by its id */
    public async load<T extends IRecord>(oid: number, record_id: RecordId): Promise<T> {
        return dispatcher.call<T>("crud.load", { oid, record_id });
    }

    /**
     * 
     * Save a record.
     * 
     * If the record has an id value, then this method will UPDATE that record.
     * Otherwise it will INSERT a new record with the given values.
     * 
     */
    public async save<T extends IRecord>(oid: number, record: T): Promise<T> {
        let rec: any = { ...record };
        // Remove technical fields before sending. There is no point in sending them.
        JOURNAL_ATTRS.forEach((fname: string) => { delete rec[fname]; })
        try {
            const result = await dispatcher.call<T>("crud.save", { oid, record: rec });
            // Automatically invalidate the LRU cache for autoviews.
            appStore.removeCachedViewRecords(oid, result.id!)            
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * 
     * Insert a record.
     * 
     * It will always try to INSERT a new record, even if the record has an id value.
     * 
     */
    public async insert<T extends IRecord>(oid: number, record: T): Promise<T> {
        let rec: any = { ...record };
        // Remove technical fields before sending. There is no point in sending them.
        JOURNAL_ATTRS.forEach((fname: string) => { delete rec[fname]; })
        return dispatcher.call<T>("crud.insert", { oid, record: rec });
    }

    /**
     * 
     * Upsert a record.
     * 
     * If a record matches the given filter, then update it with the given record
     * values. Otherwise create a new record with the given record values.
     * 
     */
    public async upsert<T extends IRecord>(oid: number, filter: { [fieldName: string]: any }, record: { [fieldName: string]: any }): Promise<T> {
        let rec: any = { ...record };
        // Remove technical fields before sending. There is no point in sending them.
        JOURNAL_ATTRS.forEach((fname: string) => { delete rec[fname]; })
        try {
            const result = await dispatcher.call<T>("crud.upsert", { oid, filter, record: rec });
            // Automatically invalidate the LRU cache for autoviews.
            appStore.removeCachedViewRecords(oid, result.id!);
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    public async deleteById(oid: number, record_id: RecordId): Promise<IRecord | null> {
        try {
            const result = await dispatcher.call<IRecord | null>("crud.delete_by_id", { oid, record_id });
            // Automatically invalidate the LRU cache for autoviews (only for logical deletions).
            if (result !== null) {
                appStore.removeCachedViewRecords(oid, result.id!);
            }
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    public async undeleteById(oid: number, record_id: RecordId): Promise<IRecord | null> {
        try {
            const result = await dispatcher.call<IRecord | null>("crud.undelete_by_id", { oid, record_id });
            // Automatically invalidate the LRU cache for autoviews (only for logical deletions).
            if (result !== null) {
                appStore.removeCachedViewRecords(oid, result.id!)
            }
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }


    }


}
