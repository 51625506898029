import { Classes, Dialog, Intent,  ProgressBar } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import React from "react";

interface IAppLoadingPageProps {
    progress: number;
}

export default class AppLoadingPage extends React.PureComponent<IAppLoadingPageProps> {
    public render() {
        return <Dialog
            isOpen={true}
            icon={IconNames.DOWNLOAD}
            title="Loading application..."
        >
            <div className={Classes.DIALOG_BODY}>
                <ProgressBar intent={Intent.PRIMARY} value={this.props.progress} />
            </div>
        </Dialog>;
    }

}
