import { Button, Card, FormGroup, H5, H6, InputGroup, Intent, Section, SectionCard, Spinner, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { makeObservable, observable, runInAction } from "mobx";
import { observer } from 'mobx-react';
import { Col, Container, Row } from "react-grid-system";
import { Api } from "../api/Api";
import { ConfirmDialog } from "../dialog/ConfirmDialog";
import { showError } from "../dialog/Notification";
import { NotificationDialog } from "../dialog/NotificationDialog";
import { appStore } from "../store/AppStore";

import _ from "lodash";
import { SubscriptionInfo, SubscriptionPerioidInfo } from "../api/Profile";
import { anyToText } from "../util";


const loading = observable.box<boolean>(false)
const setLoading = (value: boolean) => { runInAction(() => loading.set(value)) }

const subscriptions = observable.box<SubscriptionInfo[]>([])

const reload = async () => {
    try {
        setLoading(true)
        const data = await Api.profile.get_subscriptions();
        subscriptions.set(data);
        loading.set(false)
    } catch (error) {
        loading.set(false)
        showError(error)
    }
}


const debouncedReload = _.debounce(reload, 1000, { maxWait: 100000 })

@observer
export default class ProfilePage extends React.PureComponent<{}> {

    constructor(props: {}) {
        super(props)
        makeObservable(this, {})
    }

    componentDidMount(): void {
        debouncedReload()
    }

    private onDeleteAllPersonalSettings = async () => {
        try {
            if (await ConfirmDialog.open("Confirm",
                "This operation erases all of your personal settings. " +
                "This cannot be revoked. Are you sure??")) {
                await Api.userConfig.reset_all();
                await NotificationDialog.open("Warning",
                    "Your personal settings has been deleted. " +
                    "To apply all changes, you need to refresh this page (F5)."
                );
            }
        } catch (error) {
            showError(error);
        }
    }

    private renderSubscription = (s: SubscriptionInfo) => {
        return <Section key={s.id}
            collapsible={true}
            compact={true}
            title={<span>{anyToText(s.st_title)} <Tag>{s.status}</Tag></span>}
            subtitle={anyToText(s.st_description)}
        >
            {s.edit_url?
                <Button text="Click here to manage your subscription" 
                    intent={Intent.PRIMARY}
                    icon={IconNames.SETTINGS}
                    onClick={() => {
                        window.location.replace(s.edit_url)
                    }}
                />:null
            } 
            <br/>            
            {s.periods.map(p => this.renderPeriod(p))}
        </Section>
    }

    private renderPeriod = (p: SubscriptionPerioidInfo) => {
        return <SectionCard key={p.id}>
            <H6>Subscription period</H6>
            <b>Starts:</b> {anyToText(p.starts)} <br />
            <b>Ends:</b> {anyToText(p.ends)} <br />
            <b>Maximum images/Current images</b>
            {p.paid === null
                ? <Tag intent={Intent.DANGER}>100/0 (disabled)</Tag>
                : <span>{anyToText(p.max_products)} / {anyToText(p.used_products)}</span>
            }
            <br />
            <b>Fee paid:</b>  {
                p.paid === null ?
                    <Tag intent={Intent.DANGER}>UNPAID</Tag>
                    : <span><Tag intent={Intent.SUCCESS}>PAID</Tag>
                        &nbsp;
                        {p.paid_amount} {p.paid_currency_code} on  {anyToText(p.paid)}
                    </span>
            }

        </SectionCard>
    }

    public render() {
        const me = appStore.me;
        if (!me) {
            return <div>Loading</div>
        }
        const email_confirmed = me.email_confirm && me.email_confirm.created ? me.email_confirm.created : null;
        return <Card interactive={true}>
            <H5>Personal data</H5>
            <Container fluid>
                <Row>
                    <Col sm={12} md={6} lg={3}>
                        <FormGroup label="Full name" helperText="Full name of a user can only be changed by an administrator">
                            <InputGroup
                                type="text" value={me.fullname} disabled={true}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={4} lg={3}>
                        <FormGroup label="Primary e-mail" helperText="Primary e-mail can be used to login">
                            <InputGroup
                                type="text" value={me.email} disabled={true}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={4} lg={3}>
                        <FormGroup label="Confirmed" helperText="">
                            {/* TODO
                            {!!email_confirmed ?
                                <AbcInput
                                    abcValue={email_confirmed}
                                    typeCode={"timestamptz"}
                                    disabled={true}
                                /> :
                                <InputGroup type="text" value="Not confirmed yet" disabled={true} intent={Intent.DANGER} />
                            }
                            */}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={4} lg={3}>
                        {email_confirmed ?
                            <FormGroup label="Confirmation requested">
                                {/* TODO
                                <AbcInput
                                    abcValue={me.email_confirm.created}
                                    typeCode={"timestamptz"}
                                    disabled={true}
                                />
                                */}
                            </FormGroup>
                            : <FormGroup label="Request confirmation" helperText="By pressing this button, a confirmation e-mail will be sent.">
                                <Button
                                    intent={Intent.PRIMARY}
                                    text="Confirm your e-mail address"
                                    icon={IconNames.ENVELOPE}
                                    disabled={!!email_confirmed}
                                />
                            </FormGroup>
                        }
                    </Col>
                </Row>

                <H5>Subscriptions</H5>
                {loading.get() ? <Spinner /> :
                    <Row>
                        <Col sm={12}>
                            {subscriptions.get().map(s => this.renderSubscription(s))}
                        </Col>
                    </Row>
                }

                <Row>
                    <Col sm={12}>
                        <Card>
                            <H5>Reset all personal settings to default</H5>
                            <p>Most users will use the default settings on most user interfaces, but they can
                                change them. These changes include: visibility of grid columns, default
                                sort order, visibility of various input fields, layouts of forms etc.
                                It can be useful to personalize your account. However, it has a few drawbacks.
                                The developers of this system maintain the default settings. When the system
                                is upgraded, there can be changes in the UI and also in the default settings.
                                If a user saved a personalized layout (or other preference), then she will
                                not use or see the defaults. As a result, she may not see some of the
                                changes (e.g. new features) in the upgraded system. In order to enable the
                                new features, she may need to configure them manually. This is especially
                                true for form layouts and input fields.
                            </p>
                            <p>By pressing the button below, you can erase all of your personal settings.
                                After erasing your settings, it is advised to logout, refresh the page
                                and login again. This way you can reset your account to its defaults.
                            </p>
                            <Button
                                intent={Intent.DANGER}
                                text="Erase my personal settings"
                                icon={IconNames.TRASH}
                                onClick={this.onDeleteAllPersonalSettings}
                            />
                        </Card>
                    </Col>
                </Row>

            </Container>
        </Card>
    }
}
