import { observable, action, toJS, computed, IObservableArray, runInAction, makeObservable } from "mobx";
import { RelationMeta } from "../../api/Meta";
import { Api } from "../../api/Api";

export type ColumnWidthByName = { [columnName: string]: number }
export type ColumnLabelByName = { [columnName: string]: string|null }

const DEFAULT_COLUMN_WIDTH = 100;

interface IConfig {
    visible: string[];
    invisible: string[];
    widths: ColumnWidthByName;
    labels: ColumnLabelByName;
}

export class ViewFrameColumnConfiguration {
    /** Defines the key for the configuration store. */
    public readonly storagePath: string;
    @observable public readonly oid: number;
    /** Contains the names of the visible columns, in the good order. */
    @observable readonly visible: IObservableArray<string>;
    /** Contains the names of the columns that the user explicitely wanted to hide. */
    @observable readonly invisible: IObservableArray<string>;
    /** Maps column names to column widths. */
    @observable widths: ColumnWidthByName;
    /** Maps column names to (user overriden) column labels. */
    @observable labels: ColumnLabelByName;
    /** Indicates that the config was already loaded. */
    @observable isLoaded: boolean;

    constructor(storagePath: string, oid: number) {
        makeObservable(this)
        this.storagePath = storagePath;
        this.oid = oid;
        this.visible = observable<string>([]);
        this.invisible = observable<string>([]);
        this.widths = {};
        this.labels = {};
        this.isLoaded = false;
        this.updateStruct();
    }

    /** An array of widths for the visible columns. */
    @computed get visibleWidths(): number[] {
        return this.visible.map(columnName => this.widths[columnName]);
    }

    public get meta() : RelationMeta {
        return Api.meta.get_relation_meta(this.oid)
    }

    @action.bound updateStruct() {
        /* Calculate column visibility. */
        const visibility: any[] = [];
        const processed: Set<string> = new Set();

        const old_visible = this.visible.concat();
        const old_invisible = this.invisible.concat();

        const vs = this.meta;

        // Try to keep the order of visible column from the old setting.
        old_visible.forEach(columnName => {
            const aidx = vs.attr_nti[columnName]
            if (typeof aidx === "number") {
                visibility.push({ name: columnName, visible: true });
                processed.add(columnName);
            }
        });
        // Add columns that are new in the structure, and the user has not decided about them.
        vs.attrs.forEach(attr => {
            const columnName = attr.name
            if (!old_invisible.includes(columnName) && !processed.has(columnName)) {
                visibility.push({ name: columnName, visible: true });
                processed.add(columnName);
            }
        });
        // Finally, add the columns that should NOT be  visible.
        old_invisible.forEach(columnName => {
            const aidx = vs.attr_nti[columnName]
            if (typeof aidx === "number" && !processed.has(columnName)) {
                visibility.push({ name: columnName, visible: false });
            }
        });

        // Recalculate visible_columns
        const visible: string[] = [];
        visibility.forEach(vis => {
            if (vis.visible === true || vis.visible === null) {
                visible.push(vis.name);
            }
        });
        // Recalculate invisible_columns
        const invisible: string[] = [];
        visibility.forEach(vis => {
            if (vis.visible === false) {
                invisible.push(vis.name);
            }
        });
        // Recalculate widths and exclude invalid columns from labels.
        const widths: ColumnWidthByName = {};
        const labels : ColumnLabelByName = {};
        visible.forEach(columnName => {
            widths[columnName] = this.widths[columnName] || DEFAULT_COLUMN_WIDTH;
            labels[columnName] = this.labels[columnName];
        });

        // Finally, mutate the state.
        this.visible.replace(visible);
        this.invisible.replace(invisible);
        this.widths = widths;
    }

    /** Dump state to a plain Javascript object. */
    @computed get asJs(): IConfig {
        const widths: ColumnWidthByName = {};
        const labels : ColumnLabelByName = {};
        for (let colName in this.widths) {
            const width = this.widths[colName];
            if (width !== DEFAULT_COLUMN_WIDTH) {
                widths[colName] = width;
            }
            const label = this.labels[colName];
            if (label) {
                labels[colName] = label;
            }
        }
        return toJS({
            visible: this.visible,
            invisible: this.invisible,
            widths: widths,
            labels: labels
        });
    }

    /** Load state from a plain JavaScript object. The opposite of asJs. */
    @action('ViewFrameColumnConfiguration.loadFromJs') loadFromJs(data: IConfig) {
        this.visible.replace(data.visible);
        this.invisible.replace(data.invisible);
        this.widths = data.widths || {};
        this.labels = data.labels || {};
        this.updateStruct();
    }


    /** Save configration (to server) */
    public save = async (): Promise<void> => {
        return Api.userConfig.put(this.storagePath, this.asJs);
    }

    /** Reset configuration to default. */
    public reset = async (): Promise<void> => {
        try {
            await Api.userConfig.remove(this.storagePath);
            await this.load();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /** Load configuration (from server) */
    public async load(): Promise<boolean> {
        try {
            const data = await Api.userConfig.get_default_cached<IConfig>(this.storagePath, null);
            if (data === null) {
                runInAction(() => { this.isLoaded = true; })
                return false;
            } else {
                runInAction(() => { this.isLoaded = true; })
                this.loadFromJs(data);
                return true;
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    public async firstLoad(): Promise<boolean> {
        if (!this.isLoaded) {
            return this.load();
        } else {
            return false;
        }
    }

    /** Move consecutive visible columns to a new position.. */
    @action.bound public moveColumns(oldIndex: number, newIndex: number, length: number) {
        if (oldIndex === newIndex) {
            return;
        }

        const before = this.visible.slice(0, oldIndex);
        const move = this.visible.slice(oldIndex, oldIndex + length);
        const after = this.visible.slice(oldIndex + length, this.visible.length);

        let modified: string[];
        if (oldIndex < newIndex) {
            // Move to after
            const insertAt = newIndex - length - before.length + 1;
            modified = before
                .concat(after.slice(0, insertAt))
                .concat(move)
                .concat(after.slice(insertAt));
        } else {
            // Move before
            const insertAt = newIndex;
            modified = before.slice(0, insertAt)
                .concat(move)
                .concat(before.slice(insertAt))
                .concat(after);
        }
        this.visible.replace(modified);
    }


    /** Resize a visible column. */
    @action.bound public resizeVisibleColumn(index: number, size: number) {
        const columnName = this.visible[index];
        this.widths[columnName] = size;
    }


    /** Hide a visible column. */
    @action.bound public hideVisibleColumn(index: number) {
        if (index < 0 || index > this.visible.length - 1) {
            throw new Error("hideVisibleColumn: Invalid column index");
        }

        if (this.visible.length === 1) {
            throw new Error("hideVisibleColumn: At least one column must be displayed!");
        }

        const columnName = this.visible[index];
        this.visible.splice(index, 1);
        this.invisible.push(columnName);
    }

    /** Show an invisible column. */
    @action.bound public showInvisibleColumn(index: number) {
        if (index < 0 || index > this.invisible.length - 1) {
            throw new Error("showInvisibleColumn: Invalid column index");
        }
        const columnName = this.invisible[index];
        this.invisible.splice(index, 1);
        this.visible.push(columnName);
    }

    /** Check all columns */
    @action.bound public checkAll() {
        this.visible.replace(this.visible.concat(this.invisible));
        this.invisible.clear();
    }

    @action.bound public checkUncheckAll() {
        if (this.invisible.length) {
            this.checkAll();
        } else {
            this.invisible.replace(this.invisible.concat(this.visible.slice(1)));
            this.visible.replace([this.visible[0]]);
        }
    }

}