import { AbcConverter, ICustomType } from "./types";

export interface IInterval extends ICustomType {
    __type__: "interval"; // Must match with the native PostgreSQL type!
    value: number;
}

class IntervalConverter extends AbcConverter<IInterval> {
    public getTypeCode(): string {
        return "interval";
    }
    public stringToAbc(value: string | null): IInterval | null {
        return stringToInterval(value);
    }
    public abcToString(abcValue: IInterval | null): string {
        return intervalToString(abcValue);
    }
    public abcToStringHuman(abcValue: IInterval | null): string {
        return intervalToStringHuman(abcValue);
    }
    public equals(interval1: IInterval | null, interval2: IInterval | null): boolean {
        return sameInterval(interval1, interval2);
    }
}

export const intervalConverter = new IntervalConverter();

const secondsToIntervalString = (value: number | null, fractions?: number): string => {
    if (!value) {
        return "";
    } else {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value - hours * 3600) / 60);
        let seconds = value - hours * 3600 - minutes * 60;

        if (fractions === undefined) {
            seconds = Math.round(seconds);
        } else {
            const scale = 10 ** fractions;
            seconds = Math.round(seconds * scale) / scale;
        }

        let shours = hours.toString();
        let sminutes = minutes.toString();
        let sseconds = seconds.toString();

        if (hours < 10) {
            shours = "0" + shours;
        }
        if (minutes < 10) {
            sminutes = "0" + sminutes;
        }
        if (seconds < 10) {
            sseconds = "0" + sseconds;
        }
        return shours + ":" + sminutes + ":" + sseconds;
    }
};

const intervalToString = (interval: IInterval | null): string => {
    if (interval) {
        return secondsToIntervalString(interval.value);
    } else {
        return "";
    }
};


const intervalToStringHuman = (interval: IInterval | null): string => {
    if (interval) {
        const sec_num = interval.value;
        let hours: any = Math.floor(sec_num / 3600);
        let minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds: any = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    } else {
        return "";
    }
};

// "hh:mm:ss.sss" format
const INTERVAL_REG = new RegExp(/^(((\d{1,5}(\.\d+){0,1}):){0,1}(\d{1,5}(\.\d+){0,1}):){0,1}(\d{1,5}(\.\d+){0,1})$/);
// "12m" format
const INTERVAL_MIN_REG = new RegExp(/^(\d{1,5}(\.\d+){0,1})\s*[pPmM]$/);
// "12h" format
const INTERVAL_HOUR_REG = new RegExp(/^(\d{1,5}(\.\d+){0,1})\s*[hHoOóÓ]$/);
// "2.4d" format
const INTERVAL_DAY_REG = new RegExp(/^(\d{1,5}(\.\d+){0,1})\s*[dDnN]$/);

const stringToInterval = (value: string | null): IInterval | null => {
    if (!value || !value.trim()) {
        return null;
    } else {
        const res1 = value.match(INTERVAL_REG);
        if (res1) {
            // TODO: allow floats for minutes too
            // https://regex101.com/
            const hours = res1[3] ? parseFloat(res1[3]) : 0;
            const minutes = res1[5] ? parseFloat(res1[5]) : 0;
            const seconds = res1[7] ? parseFloat(res1[7]) : 0;
            return { __type__: "interval", value: hours * 3600 + minutes * 60 + seconds };
        }
        const res2 = value.match(INTERVAL_MIN_REG);
        if (res2) {
            const minutes = parseFloat(res2[1]);
            return { __type__: "interval", value: minutes * 60 };
        }
        const res3 = value.match(INTERVAL_HOUR_REG);
        if (res3) {
            const hours = parseFloat(res3[1]);
            return { __type__: "interval", value: hours * 3600 };
        }
        const res4 = value.match(INTERVAL_DAY_REG);
        if (res4) {
            const days = parseFloat(res4[1]);
            return { __type__: "interval", value: days * 24 * 3600 };
        }
        return null;
    }
};

/**
 * Returns if the given (possibly null) intervals are equal.
 *
 * Note: when passing two null values, it returns true.
 *
 */
const sameInterval = (interval1: IInterval | null, interval2: IInterval | null): boolean => {
    if ((interval1 === null) !== (interval2 === null)) {
        return false;
    } else if (!interval1 || !interval2) {
        return true;
    } else {
        return interval1.value === interval2.value;
    }
};
