import { observer } from "mobx-react";
import React from 'react';

import { Button, Classes, Dialog, FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';
import { observable, runInAction } from "mobx";
import { uiStore } from "../store/UIStore";
import { showSuccess } from "./Notification";

const shareLinkDialogOpen = observable.box(false);
const shareLinkDialogURL = observable.box("");

export const openShareLinkDialog = (url: string) => {
    runInAction(() => {
        uiStore.dialogStack.addDialog(() => <ShareLinkDialog />)
        shareLinkDialogURL.set(url)
        shareLinkDialogOpen.set(true)
    }
    );
}
export const closeShareLinkDialog = () => {
    runInAction(() => { 
        shareLinkDialogOpen.set(false) 
        uiStore.dialogStack.popLastDialog()
    })
}

export const copySharedLinkToClipboard = () => {
    navigator.clipboard.writeText(shareLinkDialogURL.get());
    showSuccess("", "Link Copied");
    closeShareLinkDialog();
}

@observer
class ShareLinkDialog extends React.PureComponent<{}> {
    public render() {
        return <Dialog
            isOpen={shareLinkDialogOpen.get()}
            icon={IconNames.SHARE}
            title={"Share link"}
            onClose={closeShareLinkDialog}
        >
            <div className={Classes.DIALOG_BODY}>
                <FormGroup
                    label="Your URL"
                    labelFor="url-input"
                >
                    <InputGroup
                        id="url-input"
                        value={shareLinkDialogURL.get()}
                        leftIcon={IconNames.LINK}
                        readOnly={true}
                    />
                </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button 
                            icon={IconNames.CLIPBOARD} 
                            intent={Intent.PRIMARY}
                            onClick={copySharedLinkToClipboard}
                            text={"Copy link to clipboard"}
                        />
                        <Button
                            icon={IconNames.CROSS}
                            onClick={closeShareLinkDialog}
                            intent={Intent.NONE}
                            text={"Close"}
                        />
                    </div>
                </div>
        </Dialog>
    }
}