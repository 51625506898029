import { enUS, hu } from 'date-fns/locale';

// TODO: add more locales
// TODO: make this depend on the globally selected language, not the navigator language
export const currentFnsLocale = () => {
    const isoCode = currentIsoCode();
    switch (isoCode) {
        case "en":
            return enUS
        case "hu":
            return hu
    default:
        return enUS
    }
}

export const currentIsoCode = () => {
    const lang = navigator.language
    if (lang && lang.length>=2) {
        const isoCode = lang.substring(0, 2).toLocaleLowerCase()
        return isoCode;
    } else {
        return "en";
    }
}
