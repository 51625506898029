import { IObservableArray, action, computed, makeObservable, observable, runInAction, toJS } from "mobx";

import { Api } from "../../api/Api";
import { RelationMeta } from "../../api/Meta";
import { IViewFilter, IViewOrderBy } from '../../api/View';

const VIEWSOURCE_DEFAULT_LIMIT = 200;
const VIEWSOURCE_DEFAULT_AUTO_RELOAD = true;

export interface IViewSourceConfiguration {
    filter: IViewFilter;
    orderBy: IViewOrderBy[];
    limit: number;
    autoReload: boolean;
}

/**
 * Represents a configuration for a ViewSource that directly affects the contents of the returned rows.
 * For the visual configuration, see ViewFrameConfiguration.
 */
export class ViewSourceConfiguration {
    /** Defines the key for the configuration store. */
    public readonly storagePath: string;
    @observable public readonly oid: number;
    @observable public filter: IViewFilter;
    @observable public readonly orderBy: IObservableArray<IViewOrderBy>;
    /** Limit the max. number of rows returned. */
    @observable public limit: number;
    /** Auto reload the viewSource after the configuration has been changed. Defaults to true. */
    @observable public autoReload: boolean;

    constructor(storagePath: string, oid: number, defaultConfig?: Partial<IViewSourceConfiguration>) {
        makeObservable(this)

        this.storagePath = storagePath;
        this.oid = oid;
        /*/ Calculate default defaults. */
        let defaultFilter: IViewFilter = { equals: {} };
        if (this.hasIsActiveColumn) {
            defaultFilter.equals["is_active"] = true;
        }
        let defaultOrderBy: IObservableArray<IViewOrderBy> = observable<IViewOrderBy>([]);
        let defaultLimit = VIEWSOURCE_DEFAULT_LIMIT;
        let defaultAutoReload = VIEWSOURCE_DEFAULT_AUTO_RELOAD;
        if (defaultConfig) {
            if (defaultConfig.filter !== undefined) {
                defaultFilter = defaultConfig.filter!;
            }
            if (defaultConfig.orderBy !== undefined) {
                defaultOrderBy = observable<IViewOrderBy>(defaultConfig.orderBy!);
            }
            if (defaultConfig.limit !== undefined) {
                defaultLimit = defaultConfig.limit;
            }
            if (defaultConfig.autoReload !== undefined) {
                defaultAutoReload = defaultConfig.autoReload;
            }
        }
        this.filter = defaultFilter;
        this.orderBy = defaultOrderBy;
        this.limit = defaultLimit;
        this.autoReload = defaultAutoReload;
    }

    @computed public get meta(): RelationMeta {
        return Api.meta.get_relation_meta(this.oid)
    }

    public get hasIsActiveColumn(): boolean {
        return this.meta.has_attr("is_active")
    }

    private updateStruct = () => {
        // TODO: update filter structure for the current viewStruct

        // We make sure that all orderbys are referencing to existing columns.
        const orderBy: IViewOrderBy[] = [];
        const meta = this.meta
        this.orderBy.forEach(item => {
            if (meta.has_attr(item.column_name)) {
                orderBy.push(item);
            }
        })
        this.orderBy.replace(orderBy);
    }

    /** Dump state to a plain Javascript object. */
    public toJs(): IViewSourceConfiguration {
        return {
            filter: toJS(this.filter),
            orderBy: toJS(this.orderBy),
            limit: toJS(this.limit),
            autoReload: toJS(this.autoReload),
        };
    }

    /** Load state from a plain JavaScript object. The opposite of asJs. */
    @action('ViewSourceConfiguration.loadFromJs') loadFromJs(data: IViewSourceConfiguration) {
        let filter: IViewFilter = data.filter;
        const meta = this.meta;
        // Remove invalid columns
        for (let columnName in { ...filter.equals }) {
            if (!meta.has_attr(columnName)) {
                console.log(" removed invalid column", columnName)
                delete filter.equals[columnName];
            }
        }
        this.filter = filter;
        let orderBy: IViewOrderBy[] = [];
        data.orderBy.forEach(item => {
            if (meta.has_attr(item.column_name)) {
                orderBy.push(item);
            }
        })
        this.orderBy.replace(orderBy);
        if (data.limit !== undefined) {
            this.limit = data.limit;
        }
        if (data.autoReload !== undefined) {
            this.autoReload = data.autoReload;
        }
        this.updateStruct();
    }


    /** Save configration (to server) */
    public save = async (): Promise<void> => {
        return Api.userConfig.put(this.storagePath, this.toJs());
    }

    /** Load configuration (from server) */
    public load = async (): Promise<boolean> => {
        try {
            const data = await Api.userConfig.get_default_cached<IViewSourceConfiguration>(this.storagePath, null);
            if (data === null) {
                return false;
            } else {
                this.loadFromJs(data);
                return true;
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /** Replace order by */
    public setOrderBy = (orderBy: IViewOrderBy[]) => {
        runInAction(() => {
            this.orderBy.replace(orderBy);
        })
    }

    @computed get simpleSearch(): string {
        return this.filter.simple || '';
    }

    @action.bound public setSimpleSearch(value: string) {
        this.filter.simple = value;
    }

    @computed get activeFilter(): boolean | null {
        const params = this.filter.equals;
        if (params) {
            return params.is_active;
        } else {
            return null;
        }
    }

    @action.bound public setActiveFilter(value: boolean | null) {
        if (!this.hasIsActiveColumn) {
            throw new Error(`Cannot setActiveFilter - ${this.meta.full_name} as no is_active column.`)
        }
        if (this.filter.equals === undefined) {
            this.filter.equals = {};
        }
        if (value === null) {
            delete this.filter.equals.is_active;
        } else {
            this.filter.equals.is_active = value;
        }
    }

    @action.bound public setFilterParam(columnName: string, value: any) {
        this.filter.equals[columnName] = value;
    }

    @action.bound public clearFilterParam(columnName: string) {
        delete this.filter.equals[columnName];
    }

}