
import { Alignment, Button, Callout, Classes, InputGroup, MaybeElement, Menu, MenuItem, Navbar, Tooltip } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import React from 'react';
import { ROUTE_PROFILE, ROUTE_SEARCH, ROUTE_SHELF } from './Routes';

import { Popover2 } from '@blueprintjs/popover2';
import { makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { appStore } from '../store/AppStore';
import { uiStore } from '../store/UIStore';
import { rootNavigate } from './CustomRouter';

interface MainHeaderProps {
  onLogout: () => void;
}

interface INavButton {
  text: string;
  title?: string;
  icon: IconName | MaybeElement;
  to?: string | null;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}
const NavButton: React.FC<INavButton> = ({ title, text, icon, to, onClick }) =>
  <Button
    className={Classes.BUTTON} large={true} minimal={true}
    icon={icon} title={title}
    onClick={
      (event: React.MouseEvent<HTMLElement>) => {
        if (to) {
          window.history.pushState(undefined, "", to);
        } else if (onClick) {          
          onClick(event);
        }
      }
    }><span className="header-button-text">{text}</span></Button>;

/*
interface INavItem {
  text: string;
  title?: string;
  icon: IconName | MaybeElement;
  to?: string | null;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}
*/

const NavItem: React.FC<INavButton> = ({ title, text, icon, to, onClick }) => {
  return <MenuItem
    text={text} icon={icon} title={title}
    onClick={(event: React.MouseEvent<HTMLElement>) => {
      if (to) {
        rootNavigate(to);
      } else if (onClick) {
        onClick(event);
      }
    }} />;
}


@observer
export default class MainHeader extends React.PureComponent<MainHeaderProps> {
  @observable private search: string = "";

  constructor(props: MainHeaderProps) {
    super(props)
    makeObservable(this)
  }

  private onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    runInAction(() => {
      uiStore.searchTextBuffer = event.currentTarget.value
    })
  }

  private onSearchKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toLowerCase() === "enter") {
      runInAction(() => {
        uiStore.searchText = uiStore.searchTextBuffer
        rootNavigate(ROUTE_SEARCH)
      })
    }
  };

  // TODO: auto login after inactivity, add countdown!
  render() {
    return <Navbar className="navbar-nav">
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading title="IMA">
          <NavItem text={"IMAGELLA"} icon={IconNames.Home} to={"/"}/>
        </Navbar.Heading>
        <Navbar.Divider />
        <Navbar.Group align={Alignment.LEFT}>
          <Tooltip
            content={<Callout icon={IconNames.INFO_SIGN}>Type in some keywords and press ENTER. You can exclude words with minus prefix.</Callout>}
            openOnTargetFocus={false}
            placement="bottom"
            usePortal={false}
          >
            <InputGroup
              id="search-input"
              value={uiStore.searchTextBuffer}
              leftIcon={IconNames.SEARCH}
              onChange={this.onSearchChange}
              onKeyPress={this.onSearchKeyPress}
              placeholder='Search for keywords'
              className="bp3-input-group"
            />
          </Tooltip>
        </Navbar.Group>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <NavItem text={"Shelf"} icon={IconNames.BOX} to={ROUTE_SHELF} />
        <Popover2 content={
          <Menu large={true}>
            <NavItem text={appStore.me ? appStore.me.fullname : "Profile"} icon={IconNames.USER} to={ROUTE_PROFILE} />
            <MenuItem text="Logout"
              title="Logout"
              icon={IconNames.LOG_OUT} onClick={this.props.onLogout} />
          </Menu>
        }
        >
          <NavButton text="" icon={IconNames.USER} />
        </Popover2>

      </Navbar.Group>
    </Navbar>
  }
}
