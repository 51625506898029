import { Session } from "./session";
import { SocketPool, WebSocketHolder } from "./socketpool";

export type WebsocketInitializer = (ws: WebSocket) => Promise<void>;

/**
 * High level communication - a message bus that can be used for different relative URLs.
 * 
 * Internally, the message bus contains a pool of websocket connections that are used to
 * make remote calls. It is also assigned a so called session - after successful login() call,
 * a session is created, and it automatically assigned to all connections that are borrowed
 * from the pool.
 */
export class MessageBus {
    private pool: SocketPool;
    private session: Session;

    constructor(relpath: string, session: Session) {
        this.pool = new SocketPool(relpath);
        this.session = session;
    }

    /**
     *
     * Call a remote method.
     *
     * @param method  name of the remote method to call
     * @param rawArgs any (arguments)
     */
    public async call<T>(method: string, rawArgs?: any): Promise<T> {
        return this.callEx(method, rawArgs, true, undefined);
    }

    /*
     * Authenticate the user with secrets password.
     *
     * This call also *identifies* the user with the socket, e.g. it assigns the new
     * JWT with the socket, and also updates the "most recent JWT" of the bus.
     */
    public async login(email: string, password: string, captcha_token: string): Promise<string> {
        try {
            const rawToken = await this.callEx<string>("security.login", { email, password, captcha_token }, false,
                (ws, rawToken) => {
                    ws.rawToken = rawToken; // assign token to the ws that was used for login
                    this.session.updateToken(rawToken); // assign token to the session too
                }
            );
            return rawToken;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /*
     * This is similar to login() but it uses an otp password instead of a permanent/stored password.
     *
     */
    public async login_with_otp(email: string, otp_password: string, remember: boolean, captcha_token: string): Promise<string> {
        try {
            const rawToken = await this.callEx<string>("security.login_with_otp", { email, otp_password, remember, captcha_token }, false,
                (ws, rawToken) => {
                    ws.rawToken = rawToken; // assign token to the ws that was used for login
                    this.session.updateToken(rawToken); // assign token to the session too
                }
            );
            return rawToken;
        } catch (error) {
            return Promise.reject(error);
        }
    }        

    /*
     * Start login process by generating and sending an OTP code.
     * This is used for subscribers only, not for admin users.
     * 
     */
    public async start_login(email: string, captcha_token: string): Promise<string> {
        try {
            return await this.callEx<string>("security.start_login", { email, captcha_token }, false);
        } catch (error) {
            return Promise.reject(error);
        }
    }    

    /*
     * Authenticate the user with an existing (maybe previously saved) raw access token.
     *
     * This call also *identifies* the user with the socket, e.g. it assigns the new
     * JWT with the socket, and also updates the "most recent JWT" of the bus, and it
     * also assigns this token to the bus (and the underlying session)
     * 
     * Note: this is only called from Session.loadFromStorage. In all other cases, CallEx
     * takes care of identification.
     */
    public async identify(rawToken: string): Promise<void> {
        try {
            await this.callEx<string>("messagebus.identify", { raw_token: rawToken }, false,
                (ws, result) => {
                    // result is null here, identify does not return the token that was sent to it
                    ws.rawToken = rawToken; // assign token to the ws that was used for login
                    this.session.updateToken(rawToken); // assign token to the session too
                }
            );
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /*
     * Renew the current token (before it expires).
     *
     * This does not return anything, but it updates the session's "more recent JWT" to
     * the new, renewed token.
     */
    public async renew(): Promise<void> {
        try {
            await this.callEx<string>(
                "messagebus.renew", null, false,
                (ws, rawToken) => {
                    ws.rawToken = rawToken; // assign token to the ws that was used for login
                    this.session.updateToken(rawToken); // assign token to the session too
                }
            );
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    }


    /**
     * Logout.
     * 
     * Destoys the session on both the server and the client side.
     * 
     */
    public async logout(): Promise<void> {
        try {
            try {
                // must call before session's token is deleted
                await this.call<string>("security.logout");
            } finally {
                this.session.deleteToken();
            }
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Prepare the socket before using it.
     * 
     * This method will check if the newest JWT that belongs to this bus
     * has been already assigned to the socket or not. If it is not, then
     * it assigns the JWT to the socket. It makes sure that further communication
     * can be done in the context of the identified user and its credentials.
     */
    private async internalIdentify(ws: WebSocketHolder, debug?:string): Promise<void> {
        try {            
            // Do we own a token that is more recent than the one that is assigned to this socket?
            let  hasToken = this.session.hasToken();
            const isTokenOutdated = !hasToken || this.session.tokenIsOutdated(ws.rawToken);
            if (isTokenOutdated) {
                // Yes, let's assign the most recent token to this socket.
                const raw_token = this.session.getRawToken();
                if (raw_token) {
                    await this.callEx<string>(                       
                        "messagebus.identify", { raw_token }, false, (ws, response) => { 
                            ws.rawToken = raw_token; 
                        }, ws
                    );
                }
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     *
     * Send a raw message to the server and wait for a reply.
     *
     * @param method  name of the remote method to call
     * @param rawArgs any (arguments)
     * @param identifyBeforeCall boolean clear this flag if you do not want to (re)identify the socket before doing the call.
     *          setting this flag to true is cheap, because it will only call identify() when needed, see internalIdentify()
     *          default value is true
     *          usually you will want to set this to false when you need to send a message that must be processed
     *          even if the user is not authenticated (for example, the authentication itself)
     * @param beforeGiveBack called back after the result has been returned but before the socket is given back to pool
     * @param ws you can specify a WebSocketHolder that will be used for communication. When nost specified, a new WebSocketHolder will
     *          be borrowed from the pool, and given back when the call is over.
     */
    private async callEx<T>(method: string, rawArgs: { [argname: string]: any } | null, identifyBeforeCall?: boolean, beforeGiveBack?: (ws: WebSocketHolder, result: T) => void, ws?:WebSocketHolder): Promise<T> {
        return new Promise(async (resolve, reject) => {
            // Prepare a connection.
            let needToGiveBack : boolean = false;
            if (ws === undefined) {
                try {
                    ws = await this.pool.borrow();
                } catch (error) {
                    return reject(error);
                }
                needToGiveBack = true;
            }
            // By default, we (re)identify the socket only, if it has not been identified yet, or the token has been changed since.
            if (identifyBeforeCall === undefined || identifyBeforeCall === true) {
                try {                    
                    await this.internalIdentify(ws);
                } catch (e) {
                    if (needToGiveBack) { this.pool.giveback(ws!); }
                    return reject(e);
                }
            }
            // Register resolver callback
            ws.socket.onmessage = (message: MessageEvent) => {
                ws!.socket.onmessage = null;
                ws!.socket.onerror = null;

                const idx1 = message.data.indexOf("|");
                if (idx1 <= 0) {
                    if (needToGiveBack) { this.pool.giveback(ws!); }
                    reject("protocol error (1)");
                }
                const op = message.data.substring(0, idx1);
                const args = message.data.substring(idx1 + 1);
                if (op === "error") {
                    if (needToGiveBack) { this.pool.giveback(ws!); }
                    reject(args);
                } else if (op === "ok") {
                    const result = JSON.parse(args) as T;
                    if (beforeGiveBack !== undefined) {
                        beforeGiveBack(ws!, result);
                    }
                    if (needToGiveBack) { this.pool.giveback(ws!); }
                    resolve(result);
                } else {
                    if (needToGiveBack) { this.pool.giveback(ws!); }
                    reject("protocol error (2)");
                }
            };
            // Register error callback
            ws.socket.onerror = (event: Event) => {
                ws!.socket.onmessage = null;
                ws!.socket.onerror = null;
                if (needToGiveBack) { this.pool.giveback(ws!); }
                // See https://stackoverflow.com/questions/25779831/how-to-catch-websocket-connection-to-ws-xxxnn-failed-connection-closed-be
                // No point in rejecting with the event, it tells almost nothing about the error itself...
                // reject(event);
                reject("Websocket connection to " + ws!.socket.url + " failed.")
            };

            // Send message and wait for answer.
            if (rawArgs === undefined) {
                rawArgs = null
            }
            const msg = "call|" + method + "|" + JSON.stringify(rawArgs)
            // For debugging only!
            console.log(msg)
            ws.socket.send(msg);
        });
    }

}
