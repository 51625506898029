import { UUID } from "crypto";
import { observer } from "mobx-react";
import React from 'react';

import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';
import { IObservableArray, makeObservable, observable, runInAction } from "mobx";
import { useInView } from 'react-intersection-observer';
import { Api } from "../api/Api";
import { showError, showSuccess, showWarning } from "../dialog/Notification";
import { ROUTE_PROFILE } from "../navigation/Routes";
import { rootNavigate } from "../navigation/CustomRouter";


export type GalleryItem = {
    product_id: UUID;
    file_id: UUID;
}

export type ProgressiveGalleryProps = {
    items: GalleryItem[]
}

export type LoadMoreButtonProps = {
    onClick?: VoidFunction
    onInView?: (inView: boolean) => void
    disabled: boolean
    text: string
}

const LoadMoreButton = (props: LoadMoreButtonProps) => {
    const { ref, inView } = useInView({ threshold: 0 });
    if (inView && props.onInView) {
        props.onInView(inView)
    }
    return (
        <div ref={ref}>
            <Button
                icon={IconNames.DOWNLOAD}
                text={props.text}
                onClick={props.onClick}
                intent={Intent.PRIMARY}
                large
                disabled={props.disabled}
            />
        </div>
    );
}

const min = (a: number, b: number) => a > b ? b : a;

@observer
class ProgressiveGallery extends React.PureComponent<ProgressiveGalleryProps> {
    @observable private n: number = 0; // number of images to diplays from items
    @observable lastImageShown: boolean = false; // set to true when the nth image is shown in the viewport
    @observable showLoadMore: boolean = false; // display the "load more" button or not?

    @observable loadedImages: IObservableArray<boolean> = observable([]);
    @observable allImagesLoaded: boolean = false; // set to true when the nth image is shown in the viewport

    @observable _key: number = 0;


    constructor(props: ProgressiveGalleryProps) {
        super(props);
        makeObservable(this)
        this.reinit()
    }

    reinit = () => {
        // console.log("reinit")
        runInAction(() => {
            this.n = min(this.props.items.length, 10) // default n is 10 or the max possible
            this.loadedImages.clear()
            this.allImagesLoaded = false
            this._key = 0;
            this.lastImageShown = false
        })
    }

    // called when the loadMore button after the nth image appears or disappears in the viewport
    onInView = (inView: boolean) => {
        // console.log("onInView", inView)
        runInAction(() => {
            this.lastImageShown = inView;
            this.loadMoreToFillViewport()
        })
    }

    // call this to add more images to the dom and load them (does nothing if there are no more images)
    loadMore = (args?: any): void => {
        runInAction(() => {
            const n = min(this.n + 10, this.props.items.length)
            // console.log("loadMore", this.n, "->", n)
            if (n > this.n) {
                this.n = n
            }
        })
    }

    // returns the number of images that are already added to the DOM but not yet loaded
    unloadedImageCount = () => {
        let notLoaded = (this.loadedImages.slice(0, this.n).filter(value => value !== true)).length;
        if (this.loadedImages.length < this.n) {
            notLoaded += this.n - this.loadedImages.length
        }
        return notLoaded
    }

    // this will call loadMore() if all images are loaded, and the last image is visible in the viewport
    loadMoreToFillViewport = () => {
        const ucnt = this.unloadedImageCount();
        // console.log("onImageLoaded, ucnt=", ucnt, " lastImageShown=", this.lastImageShown)
        if (ucnt === 0 && this.lastImageShown) {
            this.loadMore()
        }
    }

    // called when a single image is loaded, also called when an image fails to load
    onImageLoaded = (event: React.SyntheticEvent<HTMLImageElement>) => {
        const index = parseInt(event.currentTarget.getAttribute("data-idx")!)
        runInAction(() => {
            this.loadedImages[index] = true;
            this._key += 1;
            this.lastImageShown = false;
            this.loadMoreToFillViewport()
        })
    }

    addToShelf = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const productId = event.currentTarget.getAttribute("data-product-id")! as UUID;
        // console.log(productId)
        try {
            const res = await Api.shelf.add_product(productId);
            if (res.shelf === null) {
                showWarning(
                    <span>
                        You don't have an active paid subscription with at least one product available.
                        <br/>
                        <Button icon={IconNames.LINK} onClick={() => rootNavigate(ROUTE_PROFILE)}>Go to your profile</Button> 
                    </span>,
                    "Could not add to shelf"
                )
            } else if (res.is_new) {
                showSuccess("New item added to your shelf.")
            } else {
                showSuccess("Item was already on your shelf.")
            }
        } catch (error) {
            showError(error)
        }
    }


    renderItem = (product: GalleryItem, dataIdx: number) => {
        return <div key={product.file_id} className="gallery-item">
            <button 
                className="image-button" 
                onClick={this.addToShelf}
                data-product-id={product.product_id}
            >Add to shelf</button>
            <img src={`/media/file/${product.file_id}`}
                alt={product.file_id}
                data-idx={dataIdx}
                data-product-id={product.product_id}
                data-thumb-id={product.file_id}
                onLoad={this.onImageLoaded}
                onError={this.onImageLoaded}
            />
        </div>
    }


    render() {
        const canLoadMore = this.n < this.props.items.length;
        return <>
            <div className="gallery">
                {this.props.items.slice(0, this.n).map((item, index) => this.renderItem(item, index))}
            </div>
            <LoadMoreButton
                key={this._key}
                onInView={this.onInView}
                disabled={!canLoadMore}
                text={canLoadMore ? "Load more..." : "No more items"}
            />
        </>
    }
}

export default ProgressiveGallery;
