import { Button, Callout, Checkbox, Classes, Dialog, FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import ReCAPTCHA from "react-google-recaptcha";

import { computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { ChangeEvent, SyntheticEvent } from "react";
import { dispatcher } from "../App";
import { getServerConfig } from "../connection/comm";
import { isValidEmail } from "../util";

interface ILoginPageProps {
    onLoginSuccess: () => void;
}

@observer
export default class LoginPage extends React.PureComponent<ILoginPageProps> {
    @observable private auto_login: boolean = false;

    @observable private email: string = process.env.REACT_APP_DEFAULT_LOGIN_EMAIL || "";
    @observable private password: string = "";
    @observable private remember: boolean = false;
    @observable private captcha_token: string = "";
    @observable private infomessage: string = "";
    @observable private errormessage: string = "";
    @observable private overlaymessage: string = "";
    @observable private checking: boolean = false;
    @observable private login_dialog_caption = "Login";
    @observable private login_info_message = <span>If you already purchased an item, then please use the email address that you used to place your order.</span>;

    @observable private catpchaKey: number = 1;

    @observable private stage: number = 1;

    constructor(props: ILoginPageProps) {
        super(props)
        makeObservable(this)
    }

    @computed get captchaIsMissing(): boolean {
        if (false && getServerConfig().recaptcha_site_key_v2) {
            return !this.captcha_token;
        } else {
            return false;
        }
    }

    @computed get loginStage1Enabled(): boolean {
        return !!this.email && isValidEmail(this.email) && !this.captchaIsMissing;
    }

    @computed get loginStage2Enabled(): boolean {
        return this.loginStage1Enabled && !!this.password && this.password.length === 6;
    }

    onCaptchaChange = (token: string | null) => {
        runInAction(() => {
            this.captcha_token = token || ""
            if (this.captcha_token || this.auto_login) {
                if (this.loginStage2Enabled) {
                    this.onTryStage2(null);
                }
            }
        })
    }


    componentDidMount(): void {
        const location = window.location;
        if (location.search) {
            const params = new URLSearchParams(location.search);
            const email = params.get("email");
            const otp = params.get("otp");
            runInAction(() => {
                if (email) {
                    this.email = email;
                }
                if (otp) {
                    this.password = otp;
                    this.stage = 2;
                }
                if (email && otp) {
                    this.auto_login = true;
                    if (!this.captchaIsMissing) {
                        this.onTryStage2(null);
                    }
                }
            })
        }
    }


    public render() {
        if (this.overlaymessage) {
            return <Dialog
                isOpen={true}
                icon={IconNames.LOG_IN}
                title={this.login_dialog_caption}
                onClose={this.closeDialog}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        label="Your e-mail address"
                        labelFor="login-input"
                    >
                        <InputGroup
                            id="login-input"
                            value={this.email}
                            leftIcon={IconNames.TAG}
                            disabled={true}
                        />
                    </FormGroup>
                    <Callout intent={Intent.WARNING}>
                        {this.overlaymessage}
                    </Callout>
                </div>
            </Dialog>
        }

        const infomessage = this.infomessage ? this.infomessage.toString() : null;
        const errormessage = this.errormessage ? this.errormessage.toString() : null;
        const messages = <>
            {errormessage ? <Callout intent={Intent.DANGER}>{errormessage}</Callout> : null}
            {infomessage ? <Callout intent={Intent.PRIMARY}>{infomessage}</Callout> : null}
        </>;

        const captcha = getServerConfig().recaptcha_site_key_v2&&false ?
            <ReCAPTCHA
                sitekey={getServerConfig().recaptcha_site_key_v2}
                onChange={this.onCaptchaChange}
                key={this.catpchaKey}
                className="g-recaptcha"
            />
            : null;

        if (this.stage === 1) {
            return <Dialog
                isOpen={true}
                icon={IconNames.LOG_IN}
                title={this.login_dialog_caption}
                onClose={this.closeDialog}
                className="bp3-dialog"
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        label="Your e-mail address"
                        labelFor="login-input"
                        labelInfo="(required)"
                        className="bp3-form-group"
                    >
                        <InputGroup
                            id="login-input"
                            value={this.email}
                            leftIcon={IconNames.TAG}
                            onChange={this.onLoginNameChange}
                            onKeyPress={this.onKeyPress}
                            className="bp3-input-group"
                        />
                    </FormGroup>
                    {captcha}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    {messages}
                    <Callout intent={Intent.PRIMARY}>
                        {this.login_info_message}
                    </Callout>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            intent={Intent.PRIMARY}
                            icon={IconNames.FAST_FORWARD}
                            onClick={this.onTryStage1}
                            loading={this.checking}
                            disabled={!this.loginStage1Enabled}
                            className="bp3-button"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </Dialog>;
        }
        if (this.stage === 2) {
            return <Dialog
                isOpen={true}
                icon={IconNames.LOG_IN}
                title={this.login_dialog_caption}
                onClose={this.closeDialog}
                className="bp3-dialog"
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        label="E-mail"
                        labelFor="login-input"
                        labelInfo="(required)"
                        className="bp3-form-group"
                    >
                        <InputGroup
                            id="login-input"
                            value={this.email}
                            leftIcon={IconNames.TAG}
                            readOnly={true}
                            className="bp3-input-group"
                        />
                    </FormGroup>
                    <FormGroup
                        label="Password"
                        labelFor="password-input"
                        labelInfo="(required)"
                        className="bp3-form-group"
                    >
                        <InputGroup
                            id="password-input"
                            value={this.password}
                            leftIcon={IconNames.TAG}
                            onChange={this.onPasswordChange}
                            onKeyPress={this.onKeyPress}
                            className="bp3-input-group"
                            type="password"
                        />
                    </FormGroup>
                    <FormGroup
                        className="bp3-form-group"
                    >
                        <Checkbox
                            id="remember-input"
                            label="Remember"
                            checked={this.remember}
                            onChange={this.onRememberChange}
                            className="bp3-input-group"
                        />
                        {this.remember ?
                            <Callout intent={Intent.WARNING}>
                                Please check this box only, if this is your personal device, that is not used by others.
                            </Callout> : null
                        }
                    </FormGroup>
                    {captcha}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    {messages}
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            intent={Intent.NONE}
                            icon={IconNames.STAR_EMPTY}
                            onClick={this.onReset}
                            loading={this.checking}
                            className="bp3-button"
                        >
                            Start over
                        </Button>
                        <Button
                            intent={Intent.NONE}
                            icon={IconNames.StepBackward}
                            onClick={this.onTryStage1}
                            loading={this.checking}
                            disabled={!this.loginStage1Enabled}
                            className="bp3-button"
                        >
                            Resend login code
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            icon={IconNames.LOG_IN}
                            onClick={this.onTryStage2}
                            loading={this.checking}
                            disabled={!this.loginStage2Enabled}
                            className="bp3-button"
                        >
                            Login
                        </Button>
                    </div>
                </div>
            </Dialog>;
        }
    }


    private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key.toLowerCase() === "enter") {
            if (this.stage === 1 && this.loginStage1Enabled) {
                this.onTryStage1(null);
            }
            if (this.stage === 2 && this.loginStage2Enabled) {
                this.onTryStage2(null);
            }
        }
    };

    private onReset = async (event: any) => {
        runInAction(() => { 
            this.checking = false; this.captcha_token = ""; this.email = ""; this.auto_login = false; this.password = "";
            this.infomessage = ""; this.errormessage = "";
            this.stage = 1;
        })
    }

    private onTryStage1 = async (event: any) => {
        console.log('#1')
        const captcha_token = this.captcha_token;
        console.log('#2')
        runInAction(() => { this.checking = true; this.captcha_token = "" })
        console.log('#3')
        try {
            console.log('#4')
            const message = await dispatcher.start_login(this.email, captcha_token);
            console.log('#5')
            runInAction(() => { this.checking = false; this.catpchaKey += 1; this.errormessage = ""; this.infomessage = message; this.stage = 2; })
            console.log('#6')
        } catch (error) {
            runInAction(() => { this.checking = false; this.catpchaKey += 1; this.errormessage = "" + error; })
        }
    };

    private onTryStage2 = async (event: any) => {
        const captcha_token = this.captcha_token;
        runInAction(() => { this.checking = true; this.captcha_token = ""; this.auto_login = false; })
        try {
            await dispatcher.login_with_otp(this.email, this.password, this.remember, captcha_token);
            runInAction(() => { this.checking = false; })
            this.props.onLoginSuccess();
        } catch (error) {
            runInAction(() => { this.checking = false; this.catpchaKey += 1; this.errormessage = "" + error; this.infomessage = "" })
        }
    };


    private onLoginNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        runInAction(() => {
            this.email = event.target.value;
            this.errormessage = "";
        })
    };

    private onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        runInAction(() => {
            this.password = event.target.value;
            this.errormessage = "";
        })
    };

    private onRememberChange = (event: ChangeEvent<HTMLInputElement>) => {
        runInAction(() => {
            this.remember = event.target.checked;
            this.errormessage = "";
        })
    };


    private closeDialog = (event?: SyntheticEvent<HTMLElement, Event> | undefined) => { };
}
