import * as React from "react";

import { uiStore } from '../store/UIStore';

import { Dialog, Classes, Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

interface IConfirmDialogProps {
    title: string;
    body: any;
    onResolve : (value:boolean) => void;
}

export class NotificationDialog extends React.PureComponent<IConfirmDialogProps> {

    private onCloseDialog = (event?: React.SyntheticEvent<HTMLElement>) => {
        uiStore.dialogStack.popLastDialog();
        this.props.onResolve(false);
    }

    private onOkClicked = (event?: React.SyntheticEvent<HTMLElement>) => {
        uiStore.dialogStack.popLastDialog();
        this.props.onResolve(true);
    }

    public static open = async (title: string, body: any): Promise<boolean> => {
        return new Promise<boolean>( async (resolve, reject) => {
            const confirmDialog = <NotificationDialog 
                title={title} body={body} onResolve={resolve}
            />;
            uiStore.dialogStack.addDialog(()=>confirmDialog);
        });
    }

    public render() {
        return <Dialog
            isOpen={true}
            icon={IconNames.HELP}
            title={this.props.title}
            onClose={this.onCloseDialog}
        >
            <div className={Classes.DIALOG_BODY}>
                {this.props.body}
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            intent={Intent.PRIMARY}
                            icon={IconNames.CONFIRM}
                            onClick={this.onOkClicked}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    }
}
