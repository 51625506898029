import * as React from "react";

import { IObservableArray, observable, runInAction } from "mobx";
import { observer } from 'mobx-react';

import { showError, showSuccess } from "../dialog/Notification";

import _ from "lodash";
import { Api } from "../api/Api";
import { ListShelfItem } from "../api/Shelf";
import ProgressiveShelfGallery from "../components/ProgressiveShelfGallery";
import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';



const loading = observable.box<boolean>(false)
const setLoading = (value: boolean) => { runInAction(() => loading.set(value)) }


const shelf: IObservableArray<ListShelfItem> = observable([]);

const loadMoreItems = async () => {
    try {
        setLoading(true)
        const data = await Api.shelf.list(null)
        runInAction(() => {
            //shelf.replace(shelf.concat(data))
            shelf.replace(data)
            loading.set(false);
        })
    } catch (error) {
        showError(error)
    }
}

const debouncedLoadMoreItems = _.debounce(loadMoreItems, 500, { maxWait: 100000 })

const isDisplayed = observable.box(false);

const reloadShelf = async () => {
    await loadMoreItems()
    showSuccess("Shelf refreshed", undefined, 1000)
}

// auto reload in every 5 seconds
setInterval(()=> {
    if (isDisplayed.get()) {
        debouncedLoadMoreItems()
    }}, 5000)


type ShelfPageProps = {}

@observer
class ShelfPage extends React.PureComponent<ShelfPageProps> {
    componentDidMount(): void {
        debouncedLoadMoreItems()
        runInAction(() => {
            isDisplayed.set(true)
        })
    }

    componentWillUnmount(): void {
        runInAction(() => {
            isDisplayed.set(false)
        })        
    }

    render() {
        return <>
            <Button 
                icon={loading.get()?IconNames.TIME:IconNames.REFRESH} 
                disabled={loading.get()}
                intent={Intent.PRIMARY} onClick={reloadShelf} />
            <ProgressiveShelfGallery items={shelf} />
            </>
    }
}

export default ShelfPage
