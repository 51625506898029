import { makeObservable, configure as mboxConfigure, runInAction } from "mobx";
import { enableLogging as mobxEnableLogging } from 'mobx-logger';

import React from "react";

import { ScreenClassProvider, setConfiguration as setGridConfiguration } from "react-grid-system";

import { Api } from "./api/Api";
import { MessageBus, Session } from "./connection";

import { ConfirmDialog } from "./dialog/ConfirmDialog";
import MainRoutes from "./navigation/Routes";

import { observer } from 'mobx-react';
import { registerAllAbcConverters } from "./data/abc/registertypes";
import { showError } from "./dialog/Notification";
import MainHeader from "./navigation/MainHeader";
import LoginPage from "./pages/LoginPage";
import { appStore } from "./store/AppStore";
import { uiStore } from "./store/UIStore";

import { HotkeysProvider, Position, Toaster } from "@blueprintjs/core";
import { fetchServerConfig } from "./connection/comm";
import { rootNavigate } from "./navigation/CustomRouter";
import AppLoadingPage from "./pages/AppLoadingPage";
import "./style/index.scss";

mboxConfigure({ enforceActions: "observed" });
mobxEnableLogging();

export const session = new Session();
export const dispatcher = new MessageBus("call", session);
export let app: App;

registerAllAbcConverters();
setGridConfiguration({});

interface IAppState {
    sessionLoaded: boolean;
    metaLoaded: boolean;
    progress: number;
}

let _sessionLoadingStarted = false;

export const AppToaster = Toaster.create({
    position: Position.TOP,
});

@observer
export class App extends React.Component<{}, IAppState> {
    // We do this so all of these can be accessed with a single import.
    public session: Session;
    public dispatcher: MessageBus;

    constructor(props: {}) {
        super(props);
        this.state = { sessionLoaded: false, metaLoaded: false, progress: 0 };
        this.session = session;
        this.dispatcher = dispatcher;
        app = this;
        makeObservable(this, {})
    }

    public onLoginSuccess = async () => {
        try {
            const me = await Api.security.me();
            runInAction(() => {
                appStore.me = me;
            });
            this.forceUpdate(); // Nasty! Should use the store instead! How?
            if (window.location.pathname.startsWith("/login")) {
                rootNavigate("/")
            }
        } catch (error) {
            runInAction(() => {
                appStore.me = null;
            })
            showError(error);
        }
    };

    public onLogout = async () => {
        if (await ConfirmDialog.open("Confirm", "Are you sure that you want to logout?")) {
            try {
                appStore.clearAll();
                uiStore.clearAll();
                await dispatcher.logout(); // This also calls session.deleteToken();
            } finally {
                // this.forceUpdate();
                window.location.replace("/");
            }
        }
    };

    public componentDidMount() {
        /*
            With Strict Mode starting in React 18, whenever a component mounts in development, 
            React will simulate immediately unmounting and remounting the component:        

            This feature simulates user behavior such as a user tabbing away from a screen and back,
            ensuring that code will properly handle state restoration.

            The loadSession is an async method, and we want to make sure that it called only once...
        */
        if (!_sessionLoadingStarted) {
            _sessionLoadingStarted = true;
            this.loadSession();
        }
    }

    public componentWillUnmount(): void {
    }

    public render() {

        if (!this.state.sessionLoaded) {
            return <AppLoadingPage progress={0} />
        } else if (!this.state.metaLoaded) {
            return <AppLoadingPage progress={this.state.progress} />;
        }
        if (!session.isLoggedIn()) {
            return <LoginPage onLoginSuccess={this.onLoginSuccess} />;
        }
        let dialog = uiStore.dialogStack.currentDialog
        return (
            <div id="page">
                <HotkeysProvider>
                    <ScreenClassProvider>
                        {dialog ? dialog() : null}
                        <MainHeader onLogout={this.onLogout} />
                        <MainRoutes />
                    </ScreenClassProvider>
                </HotkeysProvider>
            </div>
        );
    }

    private loadSession = async () => {
        try {
            const config = await fetchServerConfig();
            console.log("config", config)
            await session.loadFromStorage(this.onLoginSuccess);
            this.setState({ sessionLoaded: true }, this.loadMeta);
        } catch (error) {
            console.log(error);
            console.log("could not restore your session from store, you need to login again");
            this.setState({ sessionLoaded: true }, this.loadMeta);
        }
    };

    private onProgress = (progress: number) => {
        this.setState({ progress });
    }

    private loadMeta = async () => {
        try {
            // disabled for the download site
            // await Api.meta.sync_all(this.onProgress);
            this.setState({ metaLoaded: true });
        } catch (error) {
            console.log(error);
            showError(error);
        }
    }

}
