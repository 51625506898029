import { observable, action, computed, makeObservable } from 'mobx';
import { ViewFrameColumnConfiguration } from './ViewFrameColumnConfiguration';

export class ViewFrameConfiguration {
    public storagePath: string;
    @observable public readonly oid: number;
    @observable public readonly columnConfiguration: ViewFrameColumnConfiguration;

    // TODO: add pagination (page size, computed page number etc.)

    constructor(storagePath: string, oid: number) {
        makeObservable(this)
        this.storagePath = storagePath;
        this.oid = oid;
        this.columnConfiguration = new ViewFrameColumnConfiguration(storagePath + ".columns", this.oid);
    }

    /** Load all configurations if they have not yet been loaded. */
    @action.bound public firstLoad() {
        this.columnConfiguration.firstLoad();
    }

    /** Load all configurations. */
    @action.bound public load() {
        this.columnConfiguration.load();
    }

    /**
     * When the configuration is created, it starts loading itself from the server,
     * and returns immediatelly. This computed property tells if the configuration was 
     * fully loaded from the server already (also set if it turns out that there was
     * no configuration stored on the server.)
     * 
    */
    @computed public get isLoaded(): boolean {
        return this.columnConfiguration.isLoaded;
    }
}