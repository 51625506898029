import { LRUCache } from 'lru-cache';
import { RecordId } from '../../api/Record';
// See https://github.com/isaacs/node-lru-cache

const DEFAULT_LRU_OPTIONS = {
    max: 100, /* Maximum size of the cache. */
    maxAge: 15000, /* Maximum age in msec */
}

/**
 * ViewRecordCache provides access to the last few values that have been
 * selected and/or loaded by lookup fields and other visual element.
 * By using a cache instead of direct record loading, you reduce the 
 * number of network messages and response times.
 * 
 */
export class ViewRecordCache {
    private oid : number;
    private cache : LRUCache<RecordId,any>;

    constructor(oid : number) {
        this.oid = oid;
        this.cache = new LRUCache<RecordId,any>(DEFAULT_LRU_OPTIONS);
    }

    public set = (id: RecordId, record:any) => {
        this.cache.set(id, record);
    }

    public get = (id:RecordId) : any | undefined => {
        return this.cache.get(id);
    }

    // TODO: maybe rename this to delete
    public del = (id:RecordId) => {
        this.cache.delete(id);
    }

    // TODO: rename this to clear()
    public reset = () => {
        this.cache.clear();
    }
}