import * as React from "react";

import { uiStore } from '../store/UIStore';

import { Dialog, Classes, Button, Intent, MaybeElement } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons/lib/esm";
import { IconNames } from "@blueprintjs/icons";

interface IConfirmDialogProps {
    title: string
    body: any
    okText ?:string
    okIcon ?: IconName | MaybeElement
    okIntent ?: Intent

    cancelText ?: string
    cancelIntent ?: Intent
    cancelIcon ?: IconName | MaybeElement
    onResolve : (value:boolean) => void
}

export class ConfirmDialog extends React.PureComponent<IConfirmDialogProps> {

    private onCloseDialog = (event?: React.SyntheticEvent<HTMLElement>) => {
        uiStore.dialogStack.popLastDialog();
        this.props.onResolve(false);
    }

    private onOkClicked = (event?: React.SyntheticEvent<HTMLElement>) => {
        uiStore.dialogStack.popLastDialog();
        this.props.onResolve(true);
    }

    public static open = async (title: string, body: any): Promise<boolean> => {
        return new Promise<boolean>( async (resolve, reject) => {
            const confirmDialog = <ConfirmDialog 
                title={title} body={body} onResolve={resolve}
            />;
            uiStore.dialogStack.addDialog(()=>confirmDialog);
        });
    }

    public render() {
        return <Dialog
            isOpen={true}
            icon={IconNames.HELP}
            title={this.props.title}
            onClose={this.onCloseDialog}
        >
            <div className={Classes.DIALOG_BODY}>
                {this.props.body}
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button 
                            icon={this.props.cancelIcon || IconNames.CROSS} 
                            onClick={this.onCloseDialog}
                            intent={this.props.cancelIntent || Intent.NONE}
                        >
                            {this.props.cancelText || "Cancel"}
                        </Button>
                        <Button
                            icon={this.props.okIcon || IconNames.CONFIRM}
                            onClick={this.onOkClicked}
                            intent={this.props.okIntent || Intent.PRIMARY}
                        >
                            {this.props.okText || "OK"}
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    }
}
