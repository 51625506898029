import React from 'react';

import { observable, runInAction } from "mobx";

import { Button, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { observer } from "mobx-react";
import { dispatcher } from "../App";
import ProgressiveGallery, { GalleryItem } from "../components/ProgressiveGallery";
import { showError, showSuccess, toaster } from "../dialog/Notification";
import { rootNavigate } from '../navigation/CustomRouter';
import { ROUTE_SHELF } from '../navigation/Routes';


interface MainPageProps { }

const loading = observable.box<boolean>(false)
const setLoading = (value: boolean) => { runInAction(() => loading.set(value)) }

const coverPageData = observable.box<GalleryItem[]>([]);

const firstDisplay = observable.box(false)

const reload = async () => {
    try {
        setLoading(true)
        const data = await dispatcher.call<GalleryItem[]>("showcase.get_cover_page_data")
        runInAction(() => {
            coverPageData.set(data);
            setLoading(false)
            if (!firstDisplay.get()) {
                firstDisplay.set(true)
                let toastId = showSuccess(
                    <span>
                        <h3>Do you want to see your licensed, downloadable items?</h3>
                        <br/>
                        <div>
                            <Button icon={IconNames.LINK} onClick={() => { rootNavigate(ROUTE_SHELF); toaster.dismiss(toastId); }}>Go to your artwork shelf</Button>
                        </div>
                    </span>,
                    "Go to your shelf",
                    15000
                )
            }
        })
    } catch (error) {
        showError(error)
    }
}
@observer
class MainPage extends React.PureComponent<MainPageProps> {
    componentDidMount(): void { reload() }

    render() {
        if (loading.get()) {
            return <Spinner />;
        } else {
            return <ProgressiveGallery items={coverPageData.get()} />
        }
    }
}

export default MainPage;
