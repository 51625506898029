/* Socket pool for (multiplexing) */

import { connect } from "./comm";

/**
 * A connection pool that creates new websocket connections as needed.
 */

 const SOCKET_POOL_MAX_SIZE = 5;

 export class WebSocketHolder {
     public socket : WebSocket;
     public rawToken : string|null;

     constructor(socket: WebSocket) {
         this.socket = socket;
         this.rawToken = null;
     }

     destroy = () => {
         this.rawToken = null;
         this.socket.close();
     }
 }

export class SocketPool {
    private relpath: string;
    private sockets: WebSocketHolder[] = [];

    constructor(relpath: string) {
        this.relpath = relpath;
    }

    public async borrow(): Promise<WebSocketHolder> {
        let ws: WebSocketHolder | undefined;
        while (!ws && this.sockets.length) {
            ws = this.sockets.pop();
            if (ws!.socket.readyState !== WebSocket.OPEN) {
                ws = undefined;
            }
        }
        if (ws) {
            //console.log("SocketPool.borrow: popped, new size is ", this.sockets.length);
            return ws;
        } else {
            //console.log("SocketPool.borrow - created (size=0)");
            try {
                let socket : WebSocket = await connect(this.relpath);
                return Promise.resolve(new WebSocketHolder(socket));
            } catch (error) {
                // See https://stackoverflow.com/questions/25779831/how-to-catch-websocket-connection-to-ws-xxxnn-failed-connection-closed-be
                // No point in rejecting with the event, it tells almost nothing about the error itself...
                // return Promise.reject(error);
                return Promise.reject("Websocket connection to " + this.relpath + " failed.")
            }
            
        }
    }

    public giveback(ws: WebSocketHolder): void {
        if (ws.socket.readyState === WebSocket.OPEN) {
            if (this.sockets.length<SOCKET_POOL_MAX_SIZE) {
                this.sockets.push(ws);                
                //console.log("SocketPool.giveback: pushed, size=", this.sockets.length);
            } else {
                ws.destroy();
            }
        }
    }
}
