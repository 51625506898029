import { UUID } from "crypto";
import { dispatcher } from "../App";
import { JournaledRecord } from "../data/abc/record";
import { ITimestamp } from "../data/abc/timestamptz";

export type ShelfRecord = JournaledRecord & {
	product_id : UUID
	subscription_period_id : UUID
	sys_user_id : UUID 
}

export type AddProductResult = {
    shelf: ShelfRecord|null;
    is_new: boolean;
}

export type ListShelfItem = {
    id        : UUID
    c_tim     : ITimestamp
    product_id: UUID
    file_id   : UUID
    completed : ITimestamp|null
    failed    : ITimestamp|null
    nwaiting  : number|null
}

export class Shelf {
    /**
     * 
     * Return information about the currently logged in user.
     * 
     */
    public async add_product(product_id: UUID): Promise<AddProductResult> {
        return dispatcher.call<AddProductResult>("shelf.add_product", { product_id });
    }

    public async list(id_after : UUID|null) : Promise<ListShelfItem[]> {
        return dispatcher.call<ListShelfItem[]>("shelf.list", { id_after });
    }

    public async create_zip_download_link(product_id: UUID): Promise<string> {
        return dispatcher.call<string>("shelf.create_zip_download_link", { product_id });
    }

}
