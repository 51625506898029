import { dispatcher } from "../App";
import { LRUCache } from 'lru-cache';

const DEFAULT_LRU_OPTIONS: LRUCache.Options<string, any, unknown> = {
    max: 100, /* Maximum size of the cache. */
    ttl: 15000, /* Maximum age in msec */
}

/**
 * General server-side user configuration store.
 */
export class UserConfig {
    private cache: LRUCache<string, any> = new LRUCache<string, any>(DEFAULT_LRU_OPTIONS);

    public async get<T>(key: string, ttl?: number): Promise<T> {
        try {
            const result = await dispatcher.call<T>("user_config.cfg_get", { key });
            this.cache.set(key, result, { ttl });
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    public async get_default<T>(key: string, default_value: any, ttl?: number): Promise<T> {
        // TODO: maybe we could save bandwith by not sending the default_value here?
        try {
            const result = await dispatcher.call<T>("user_config.cfg_get_def", { key, "default": default_value });
            this.cache.set(key, result, { ttl });
            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    public async get_cached<T>(key: string, ttl?: number): Promise<T> {
        const result = this.cache.get(key);
        if (result === undefined) {
            return this.get(key, ttl);
        } else {
            return result;
        }
    }

    public async get_default_cached<T>(key: string, default_value: any, ttl?: number): Promise<T> {
        const result = this.cache.get(key);
        if (result === undefined) {
            return this.get_default(key, default_value, ttl);
        } else {
            return result;
        }
    }

    public async put<T>(key: string, value: any): Promise<T> {
        this.cache.set(key, value);
        return dispatcher.call<T>("user_config.cfg_put", { key, value });
    }

    /** Warning, this NEVER uses the cache! */
    public async exist<T>(key: string): Promise<T> {
        return dispatcher.call<T>("user_config.cfg_exist", { key });
    }

    /** Warning, this NEVER uses the cache! */
    public async remove<T>(key: string): Promise<T> {
        this.cache.delete(key);
        return dispatcher.call<T>("user_config.cfg_remove", { key });
    }

    /** Warning, this NEVER uses the cache! */
    public async find_one_and_delete<T>(key: string): Promise<T> {
        this.cache.delete(key);
        return dispatcher.call<T>("user_config.cfg_find_one_and_delete", { key });
    }

    /** Reset all personal settings */
    public async reset_all(): Promise<void> {
        return dispatcher.call<void>("user_config.cfg_remove_all", {})
            .then(() => {
                this.cache.clear();
            });
    }

    /** Reset all personal settings for a given key prefix */
    public async reset_prefix(key_prefix: string): Promise<void> {
        return dispatcher.call<void>("user_config.cfg_remove_prefix", { key_prefix })
            .then(() => {
                this.cache.clear();
            });
    }
}
