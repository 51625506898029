export interface ICustomType {
    __type__: string;
}

export abstract class AbcConverter<T extends ICustomType> {
    public abstract getTypeCode(): string;
    public abstract stringToAbc(value: string | null): T | null;
    public abstract abcToString(abcValue: T | null): string;
    public abstract abcToStringHuman(abcValue: T | null): string;
    public abstract equals(abcValue1: T | null, abcValue2: T | null): boolean;
}
