import { UUID } from "crypto";
import { dispatcher } from "../App";
import { HStore, JournaledRecord } from "../data/abc/record";
import { ITimestamp } from "../data/abc/timestamptz";

export type SubscriptionPerioidInfo = JournaledRecord & {
    starts: ITimestamp;
    ends: ITimestamp;
    max_products: number;
    used_products: number;
    paid: ITimestamp|null;
    paid_amount: number|null;
    paid_currency_id: UUID|null;
    paid_currency_code: string|null;
}

export type SubscriptionInfo = {
    id : UUID;
    edit_url: string;
    status: string;
    sys_user_id : UUID;
    subscription_type_id : UUID;
    st_code: string;
    st_title: HStore;
    st_description: HStore | null;
    first_period_starts: ITimestamp | null;
    last_period_ends: ITimestamp | null;
    periods: SubscriptionPerioidInfo[];
}

export class Profile {
    public async get_subscriptions(): Promise<SubscriptionInfo[]> {
        return dispatcher.call<SubscriptionInfo[]>("profile.get_subscriptions");
    }
}
