import { IRecord, RecordId } from "../../api/Record";


import { action, computed, observable, runInAction } from 'mobx';
import { Api } from "../../api/Api";
import { Crud } from "../../api/Crud";
import { showError } from '../../dialog/Notification';

export type ChangedFields = { [fieldName: string]: boolean };

export interface ILastSavedRecord {
    record: IRecord;
    wasNew: boolean;
}

export default class RecordSource {
    /** 
     * Current record, contains values for all fields. 
     * This is the local version that may be different from what was originally loaded
     * from the database.
     */
    @observable public record: IRecord;
    /**
     * Original record. If the record was loaded from the database, then it contains the loaded original
     * version. If it was created locally, then it contains the default unaltered field values.
     */
    @observable public origRecord: IRecord;
    /** Oid of the table. */
    @observable public oid: number;
    /** Changed fields, based on the difference between origRecord and record. */
    @observable public changedFields: ChangedFields;
    /** This is true if the record has ANY change (e.g. at least one true value in changedFields). */
    @observable public hasChange: boolean;
    /** Indicates that the record is being loaded from the server. */
    @observable public loading: boolean;
    /** Stores the last saved record. This is also updated on local delete operations. */
    @observable public lastSaved: ILastSavedRecord | null;
    /** Tells if the record exists in the database. */
    @observable public exists: boolean;
    /**
     * defaultValues used by createNewRecord() to populate field values of new records. 
     * You will almost never want to modify this directly. Use setDefaultValues() instead.
     * 
     */
    @observable public defaultValues?: IRecord;

    public constructor(oid: number) {
        this.oid = oid;
        this.record = {};
        this.origRecord = {};
        this.defaultValues = {};
        this.lastSaved = null;
        this.changedFields = {};
        this.hasChange = false;
        this.exists = false;
        this.loading = true; // We are loading (creating a new record).
        // DONT CALL CREATENEWRECORD HERE! BECAUSE IT IS AN ASYNC METHOD!
        // WHO KNOWS WHEN IT WILL OVERWRITE THE CURRENT RECORD???
        //this.createNewRecord(); // We create a new record so that we have a valid id that can be referenced.
    }

    @computed get meta() {
        return Api.meta.get_relation_meta(this.oid)
    }

    /** Identifier of the current record. */
    @computed get recordId(): RecordId | null {
        return this.record.id || null;
    }

    /** Supports logical deletions. */
    @computed get hasIsActive(): boolean {
        return this.meta.has_attr("is_active")
    }

    /** Current record is active (not deleted). */
    @computed get isActive(): boolean {
        return !!(this.record as any)["is_active"];
    }


    /** Tell if the given field was changed.  */
    public isFieldChanged = (fieldName: string) => {
        return !!this.changedFields[fieldName];
    }

    /** Get value of a field. */
    public getFieldValue = (fieldName: string): any => {
        const result = (this.record as any)[fieldName];
        return (result === undefined) ? null : result;
    }

    /** Replace the record with the given one. This will reset changedFields and hasChange. */
    @action('RecordSource.setRecord') setRecord = (record?: IRecord) => {
        this.origRecord = record === undefined ? { id: null } : record;
        this.record = { ...record };
        this.changedFields = {};
        this.hasChange = false;
    }

    @action.bound setLoading() {
        this.loading = true;
    }

    @action.bound clearLoading() {
        this.loading = false;
    }

    /** Load record from database. This is an async operation that will change the state later. */
    public loadRecord = async (recordId: RecordId) => {
        this.setLoading();
        try {
            const record = await Api.crud.load<IRecord>(this.oid, recordId);
            runInAction(() => {
                this.setRecord(record);
                this.exists = true;
                this.clearLoading();
            })
            return Promise.resolve();
        } catch (error) {
            showError(error);
            return Promise.reject(error);
        }
    }

    @action.bound setFieldValue(fieldName: string, value: any) {
        (this.record as any)[fieldName] = value;
        this.changedFields[fieldName] = true;
        this.hasChange = true;
    }

    /** 
     * Set default values, and also change the current record to defaults
     * if it does not exist in the database.
     */
    @action.bound public setDefaultValues(defaultValues?: IRecord) {
        this.defaultValues = defaultValues;
        if (!this.exists) {
            let record = this.record;
            this.copyDefaultValuesIntoRecord(record);
            this.setRecord(record);
        }
    }

    /** Copy default values into a record. Warning: this does not set the record id! */
    @action.bound public copyDefaultValuesIntoRecord(record: any) {
        this.meta.attrs.forEach((attr) => {
            if (this.defaultValues && (this.defaultValues as any)[attr.name] !== undefined) {
                record[attr.name] = (this.defaultValues as any)[attr.name];
            } else {
                throw new Error("TODO: AttributeMeta should return its default value")
                /*
                const fs = this.meta.attr(attr.name);
                if (fs.default !== null && fs.default !== undefined) {
                    record[fieldName] = fs.default;
                }
                */
            }
        });
    }

    /* 
        Save record into database. 
    
        This will set the current record to the saved version (possibly modified by the server),
        and reset reset changedFields and hasChange.

    */
    public saveRecord = async (): Promise<void> => {
        const wasNew = !this.exists;
        try {
            let record: IRecord;
            if (wasNew) {
                record = await Api.crud.insert<IRecord>(this.oid, this.record);
            } else {
                record = await Api.crud.save<IRecord>(this.oid, this.record);
            }
            runInAction(() => {
                this.setRecord(record);
                // We do this so that others can react.
                this.lastSaved = { record: { ...record }, wasNew };
            });
        } catch (error) {
            showError(error);
            return Promise.reject(error);
        }
    }



    /** Create a new record. This will reset changedFields and hasChange. */
    public createNewRecord = async (): Promise<void> => {
        this.setLoading();
        try {
            // Reuse previous unsaved generated identifier, if possible.            
            let recordId = this.exists ? undefined : this.recordId;
            if (recordId === undefined || recordId === null) {
                recordId = await Crud.nextId(this.oid);
            }
            const record: any = { id: recordId };
            this.copyDefaultValuesIntoRecord(record);
            runInAction(() => {
                this.setRecord(record);
                this.loading = false;
                this.exists = false;
            });
            return Promise.resolve();
        } catch (error) {
            showError(error);
            return Promise.reject();
        }
    }

    public deleteRecord = async (): Promise<void> => {
        try {
            const record = await Api.crud.deleteById(this.oid, this.recordId!);
            if (record === null) {                
                runInAction(() => {
                    this.createNewRecord();
                    // We do this so that others can react. ListEditorPage will reload
                    // the assigned viewSource when lastSaved is changed.
                    this.lastSaved = { record: { }, wasNew: false };
                });
            } else {
                runInAction(() => {
                    this.setRecord(record);
                    // We do this so that others can react. ListEditorPage will reload
                    // the assigned viewSource when lastSaved is changed.
                    this.lastSaved = { record: { ...record }, wasNew: false };
                });
            }
        } catch (error) {
            showError(error);
            return Promise.reject(error);
        }
    }

    public undeleteRecord = async (): Promise<void> => {
        try {
            const record = await Api.crud.undeleteById(this.oid, this.recordId!);
            runInAction(() => {
                this.setRecord(record!);
                // We do this so that others can react.
                this.lastSaved = { record: { ...record }, wasNew: false };
            });
        } catch (error) {
            showError(error);
            return Promise.reject(error);
        }
    }


}