import * as React from "react";
import {
  Route,
  Routes
} from "react-router-dom";
import MainPage from "../pages/MainPage";
import ProfilePage from "../pages/ProfilePage";
import SearchPage from "../pages/SearchPage";
import CustomRouter from "./CustomRouter";
import ShelfPage from "../pages/ShelfPage";

export const ROUTE_PROFILE = "/profile";
export const ROUTE_SEARCH = "/search";
export const ROUTE_SHELF = "/shelf";

interface IRoutesProps { }


export default class MainRoutes extends React.Component<IRoutesProps, {}> {
  public render() {
    return <CustomRouter>
      <Routes >
        <Route path="/" element={<MainPage />}></Route>
        <Route path={ROUTE_SEARCH} element={<SearchPage />}></Route>
        <Route path={ROUTE_PROFILE} element={<ProfilePage />}></Route>
        <Route path={ROUTE_SHELF} element={<ShelfPage />}></Route>
      </Routes>
    </CustomRouter>
  }
}
