import { IToken, decodeToken } from "./token";
import { now } from "../util";
import { dispatcher } from "../App";
import Cookies from "./cookies";
import { getServerConfig } from "./comm";

const RAW_TOKEN_KEY: string = "RAW_TOKEN";
const ACCESS_TOKEN_COOKIE_NAME : string = "_token"

/**
 * Session is a singleton that holds a token for the application.
 */
export class Session {
    // This is the official, most up to date token of the application.
    private token: IToken | null;
    private _renewTimer: NodeJS.Timeout | null;

    constructor() {
        this.token = null;
        this._renewTimer = null;
    }

    private startRenewalTimer = () => {
        this.stopRenewalTimer(); // stop the previous one

        if (!this.token) {
            throw new Error(`Cannot start renewal on a null token (${this.token}).`)
        }
        // Calculate a point in time when the token should be renwed.
        const sec_remains = this.token!.exp - now();
        if (sec_remains < 0) {
            throw new Error(`Cannot start renewal on an already expired token (${sec_remains} s).`);
        }
        const renewIn = sec_remains - getServerConfig().token_renew_window_seconds*0.9;
        //console.log("Starting renew timer, sec_remains="+sec_remains + " renewIn="+renewIn)
        this._renewTimer = setTimeout(this.doRenew, renewIn * 1000);
    }

    private stopRenewalTimer = () => {
        //console.log("Stopping renew timer")
        if (this._renewTimer) {
            clearTimeout(this._renewTimer);
            this._renewTimer = null;
        }
    }

    private doRenew = async () => {
        try {
            await dispatcher.renew();
            //console.log("Token renewed.")
        } catch (error) {
            this.token = null;
            console.log(error);
            console.log("Could not renew token");
            throw error;
        }
    }

    /**
     * Load last known session from local storage.
     * 
     * This will also check the tvu value, and set token to null if  the token has already expired.
     * (E.g. it will never load an expired token). It will also stop any previously started renewal
     * timer, and start a new one that keeps the token valid while the application is alive.
     * 
    */
    public loadFromStorage = async (onIdentified: () => void) => {
        this.stopRenewalTimer();
        this.token = null;

        const rawToken = localStorage.getItem(RAW_TOKEN_KEY);
        if (rawToken) {
            let loadedToken = decodeToken(rawToken);
            if (loadedToken) {
                if (loadedToken.exp && loadedToken.exp > now()) {
                    let identified: boolean = false;
                    try {
                        await dispatcher.identify(rawToken);
                        identified = true;
                    } catch (error) {
                        return Promise.reject(error)
                    }
                    if (identified) {
                        onIdentified();
                        this.token = loadedToken;
                        this.startRenewalTimer();
                    }
                }
            } // else - token expired
        }
    }

    /** 
     * Update the official token of the application.
     * 
     * It also stores it in the local storage, and starts a a background timer that keeps the token
     * valid while the application is alive.
     * */
    public updateToken = (rawToken: string) => {
        const token = decodeToken(rawToken);
        localStorage.setItem(RAW_TOKEN_KEY, rawToken);
        const expiresInMsec = token.exp - now();
        Cookies.set(ACCESS_TOKEN_COOKIE_NAME, rawToken, expiresInMsec / 3600.0 / 24.0);
        this.token = token;
        this.startRenewalTimer();
    }

    /** 
     * 
     * Remove the official token (e.g. set it to null).
     * 
     * This also removes the token from the local storage, and stops the token renewal timer.
     * 
     * */
    public deleteToken = () => {
        localStorage.removeItem(RAW_TOKEN_KEY);
        this.stopRenewalTimer();
        this.token = null;
    }

    /** The application is logged in to the server with a valid user. */
    public isLoggedIn = (): boolean => {
        if (this.token) {
            if (this.token.sub) {
                return true;
            }
        }
        return false;
    }

    public hasToken(): boolean {
        return this.token != null;
    }

    /**
     * Tells if the raw token last used for a messagebus is outdated.
     * 
     * Warning - this DOES NOT check the expiration time (exp) of the token.
     * It only tells if the application has received a new token that is
     * different from the argument.
     * 
     * @param rawToken the raw token that was last used by the message bus.
     * @return: if the official token of the application is different
     */
    public tokenIsOutdated = (rawToken: string | null): boolean => {
        if (rawToken === null && this.token === null) {
            return false; // both are null
        } else if ((rawToken === null) !== (this.token === null)) {
            // one is null the other is not -> outdated
            return true;
        } else if (rawToken === this.token!.raw) {
            // they are not nulls and they are equal
            return false;
        } else {
            // they are not null and they are not equal. whatver...
            return true;
        }
    }

    public getRawToken = (): string | null => {
        if (this.token) {
            return this.token!.raw;
        } else {
            return null;
        }
    }

}
