import * as React from 'react';

import { IconNames } from "@blueprintjs/icons";
import { action, computed, IObservableArray, observable, toJS, runInAction } from "mobx";
import { uiStore } from "../UIStore";
import { NumericInput, InputGroup } from "@blueprintjs/core";

export interface IDynamicLayoutConfigurationOptionsBase {
    componentKey: string | null; // Name of the component to be displayed.
    type: string; // Type of the config item.
}

export type IDynamicLayoutConfigurationOptions = IDynamicLayoutConfigurationOptionsBase & any;

/** Layout types */
export const LAYOUT_TYPES: string[] = [
    "container", "row", "column", "tabs", "tab", "component", "card", "empty"];

/** Types that are allowed to be roots. */
export const LAYOUT_ROOT_TYPES: string[] = [
    "container", "tabs", "component", "card", "empty"
]

/** List possible child types for all parent types. */
export const LAYOUT_ALLOWED_CHILDREN: { [parentType: string]: string[] } = {
    container: ["row"],
    row: ["column"],
    column: LAYOUT_ROOT_TYPES,
    component: [],
    empty: [],
    tabs: ["tab"],
    tab: LAYOUT_ROOT_TYPES,
    card: LAYOUT_ROOT_TYPES,
}

export const LAYOUT_TYPE_LABELS: { [type: string]: string } = {
    empty: "Üres",
    component: "Komponens",
    tabs: "Fülek",
    tab: "Fül",
    container: "Konténer (fluid)",
    column: "Oszlop",
    row: "Sor",
    card: "Kártya",
};
export const LAYOUT_TYPE_ICONS: { [type: string]: string } = {
    empty: IconNames.CIRCLE,
    component: IconNames.WIDGET,
    tabs: IconNames.KEY_TAB,
    tab: IconNames.WIDGET_BUTTON,
    container: IconNames.LAYOUT_AUTO,
    row: IconNames.ADD_ROW_BOTTOM,
    column: IconNames.COLUMN_LAYOUT,
    card: IconNames.SIM_CARD,
};

const EMPTY_LAYOUT_OPTIONS: IDynamicLayoutConfigurationOptions = {
    componentKey: null,
    type: "empty",
};

export class DynamicLayoutConfiguration {
    /** The only two ovservables that are saved into the database. */
    @observable public options: IDynamicLayoutConfigurationOptions;
    @observable public items: IObservableArray<DynamicLayoutConfiguration>;
    /** This is managed automatically. */
    private _parent: DynamicLayoutConfiguration | null = null;

    /** Used by the layout editor to expand/collapse. */
    @observable public isExpanded: boolean = true;

    @computed get type() {
        return this.options.type;
    }

    @computed get typeLabel(): string {
        return LAYOUT_TYPE_LABELS[this.type] || "Ismeretlen";
    }

    public isContained = (parent: DynamicLayoutConfiguration): boolean => {
        if (parent === this) {
            return true
        } else if (this._parent !== null) {
            return this._parent.isContained(parent);
        } else {
            return false;
        }
    }

    constructor(options?: IDynamicLayoutConfigurationOptions, items?: DynamicLayoutConfiguration[]) {
        this.options = options === undefined ? { ...EMPTY_LAYOUT_OPTIONS } : options;
        this.items = observable<DynamicLayoutConfiguration>(items === undefined ? [] : items);
    }

    get parent() { return this._parent }

    @action.bound public setType(type: string) {
        if (LAYOUT_TYPE_LABELS[type]) {
            this.options.type = type;
            if (type !== "component") {
                this.options.componentKey = null;
            }
        } else {
            throw new Error("Invalid type:" + type);
        }
    }

    @action.bound public addItem(type: string) {
        if (!LAYOUT_ALLOWED_CHILDREN[this.type].includes(type)) {
            throw new Error("Érvénytelen gyermek típus!");
        }
        const newItem = new DynamicLayoutConfiguration({ type, componentKey: null });
        newItem._parent = this;
        this.items.push(newItem);
    }

    @action.bound public remove() {
        if (this._parent) {
            this._parent!.items.remove(this);
            this._parent = null;
        } else {
            throw new Error("DynamicLayoutConfiguration: cannot remove a parentless config.");
        }
    }

    @action.bound moveUnder(newParent: DynamicLayoutConfiguration) {
        if (this._parent) {
            // Remove from current parent
            this._parent.items.remove(this);
            // Add to new parent
            newParent.items.push(this);
            this._parent = newParent;
        } else {
            throw new Error("A gyökér elemet nem lehet mozgatni.");
        }
    }

    @computed get itemIndex(): number | null {
        if (this._parent === null) {
            return null;
        } else {
            return this._parent.items.indexOf(this);
        }
    }

    @computed get prevSibling(): DynamicLayoutConfiguration | null {
        const parent = this._parent;
        if (parent === null) {
            return null;
        } else {
            const idx = parent.items.indexOf(this);
            if (idx > 0) {
                return parent.items[idx - 1];
            } else {
                return null;
            }
        }
    }

    @computed get nextSibling(): DynamicLayoutConfiguration | null {
        const parent = this._parent;
        if (parent === null) {
            return null;
        } else {
            const idx = parent.items.indexOf(this);
            if (idx === parent.items.length - 1) {
                return null;
            } else {
                return parent.items[idx + 1];
            }
        }
    }

    @action.bound swapWith(sibling: DynamicLayoutConfiguration) {
        const parent = this._parent;
        if (parent === null || sibling._parent === null || parent !== sibling._parent) {
            throw new Error("Csak testvér elemeket lehet fölcserélni.")
        } else {
            const myIndex = parent.items.indexOf(this);
            const hisIndex = parent.items.indexOf(sibling);
            parent.items[myIndex] = sibling;
            parent.items[hisIndex] = this;
        }
    }


    /** Deserialize configuration. */
    public static fromJS(data: any, parent?: DynamicLayoutConfiguration | null): DynamicLayoutConfiguration {
        const result = new DynamicLayoutConfiguration(data.options);
        if (parent) {
            result._parent = parent;
        }
        if (data["items"]) {
            result.items.replace(data.items.map((item: any) => DynamicLayoutConfiguration.fromJS(item, result)));
        }
        return result;
    }

    /** Serialize the configuration. */
    public toJS(): Object {
        const result: any = { options: this.options }
        if (this.items.length) {
            result.items = this.items.map(item => item.toJS());
        }
        return toJS(result);
    }

    public save = async (path: string): Promise<void> => {
        return uiStore.savelayoutConfiguration(path, this);
    }

    private changeOptionText (name: string, event:React.ChangeEvent<HTMLInputElement>) {
        this.changeOption(name, event.target.value);
    }

    private changeOption (name: string, value:any) {
        runInAction(()=>{
            this.options[name] = value;
        })        
    }

    public getOption = (name: string, defVal?:any) : any => {
        if (this.options[name]===undefined) {
            return defVal;
        } else {
            return this.options[name];
        }
    }

    /* TODO: maybe put these into a separate options editor file? */
    public createOptionsEditor = () : any | null => {
        if (this.type==="column") {
            return <>
                    <NumericInput min={0}  max={12} value={this.options["sm"] || 0} style={{width: "3em"}}
                        onValueChange={this.changeOption.bind(this, "sm")}
                    />
                    <NumericInput min={0}  max={12} value={this.options["md"] || 0} style={{width: "3em"}}
                        onValueChange={this.changeOption.bind(this, "md")}
                    />
                    <NumericInput min={0}  max={12} value={this.options["lg"] || 0} style={{width: "3em"}}
                        onValueChange={this.changeOption.bind(this, "lg")}
                    />
                </>
        } else if (this.type==="tab") {
            return <InputGroup type="text" value={this.options["title"] || "" }
                onChange={this.changeOptionText.bind(this,"title")}
            />

        } else if (this.type==="card") {
            return <>
                <InputGroup type="text" value={this.options["title"] || "" }
                    onChange={this.changeOptionText.bind(this,"title")}
                />
                <InputGroup type="text" value={this.options["description"] || "" }
                    onChange={this.changeOptionText.bind(this,"description")}
                />
                </>
        } else {
            return null;
        }
    }

}
